import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Theme } from "../../../types";
import { IHeader } from "./interfaces";

export const HeaderWrapper = styled.header<
  IHeader & {
    variant: Theme;
  }
>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: ${({ variant, theme }) =>
    variant === Theme.DARK
      ? `${theme.colors.black[900]}a3`
      : `${theme.colors.white[700]}57`};
  backdrop-filter: blur(10px);
  font-family: "Montserrat", sans-serif;
  transition-property: opacity;
  transition-duration: var(--transition-duration);
  z-index: 4;

  ${({ hideBeforeScroll }) =>
    hideBeforeScroll &&
    css`
      opacity: 0;
      pointer-events: none;

      &.visible {
        opacity: 1;
        pointer-events: all;
      }
    `};
`;

export const HeaderContent = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: none;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 12px 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 6px;
  }
`;
