export enum SectionVariant {
  LIGHT = "light",
  DARK = "dark",
}

export interface BackgroundTextProps {
  text?: string;
  showTablet?: boolean;
  centeredVertically?: boolean;
  positionRight?: number;
  positionRightTablet?: number;
  fontSize?: number;
  fontSizeTablet?: number;
  opacity?: number;
  backgroundImage?: string;
}

export interface ISection {
  title?: React.ReactElement | string;
  description?: React.ReactElement | string;
  id?: string;
  variant?: SectionVariant;
  leftColumnsNum?: number;
  centeredVerticallyColumns?: boolean;
  backgroundContent?: React.ReactNode;
  backgroundTextProps?: BackgroundTextProps;
  minHeight?: string;
  contentFullWidth?: React.ReactNode | React.ReactNode[];
  reversed?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export interface IColumn {
  leftColumnsNum: number;
  isTablet: boolean;
  centeredVerticallyColumns: boolean;
}
