import styled from "@emotion/styled";

export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  font-size: 16px;
  box-sizing: border-box;
  background: #000000;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    font-size: 24px;
    padding-bottom: 140px;
  }

  z-index: 100;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  text-align: center;
  padding: 24px 24px;
  width: 100%;
  background: #000000;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, min-content);
    grid-template-rows: 1fr;
    gap: 36px;
    text-align: center;
    font-size: 16px;
    a:nth-of-type(1) {
      text-align: right;
    }
    a:nth-of-type(2) {
      text-align: left;
    }
  }
  z-index: 100;
`;


export const FooterContentLabel = styled.div`
  margin-bottom: 114px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    margin-top: 89px;
    margin-bottom: 410px;
  }
  
  .label {
    @media screen and (min-width: 768px) {
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 56px;
      margin-bottom: 25px;
    }
    
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    padding: 92px 24px 33px 24px;
    width: 100%;
    box-sizing: border-box;
  }
`

export const Btn = styled.div`
  @media screen and (min-width: 768px) {
    padding: 17px 89px 16px 89px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    :hover {
      border-color: ${(props) => props.theme.colors.blue.steel};
      background-color: ${(props) => props.theme.colors.blue.steel};
    }
  }
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #EFEAEA;
  padding: 6px 26px 8px 26px;
  border: 1px solid #0EAECA;
  background-color: #0EAECA;
  border-radius: 24px;  
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
`