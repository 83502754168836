import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ButtonAppleSize, ButtonAppleVariant } from "./ButtonApple";

export const ButtonStyled = styled.button<{
  size: ButtonAppleSize;
  variant: ButtonAppleVariant;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #2c72db;
  font-weight: bold;
  transition-duration: var(--transition-duration);
  color: ${({ theme }) => theme.colors.white[700]};

  ${({ size }) => {
    switch (size) {
      case ButtonAppleSize.SMALL:
        return css`
          padding: 8px 26px;
          border-radius: 24px;
          font-size: 14px;
        `;
      case ButtonAppleSize.MEDIUM:
        return css`
          padding: 8px 40px;
          border-radius: 24px;
          font-size: 20px;
        `;
      default:
        return css`
          padding: 20px 36px;
          border-radius: 18px;
          font-size: 24px;
        `;
    }
  }}

  ${({ variant }) => {
    switch (variant) {
      case ButtonAppleVariant.TEXT:
        return css`
          padding: 0;
          background-color: transparent;
          color: #2c72db;
        `;
    }
  }}

  :hover {
    filter: brightness(1.1);
  }

  :active {
    filter: brightness(0.9);
  }
`;
