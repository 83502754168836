import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Layout } from "../../widgets/Layout";
import { Section } from "../../widgets/Section";
import {
  StatusMessage,
  StatusMessageVariants,
} from "./components/StatusMessage";
import { StatusMessageWrapper } from "./styles";
import { getOrdersNumber } from "../../../utils/getOrdersNumber";

const ThankYouPage: React.FC = () => {
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState<number>();

  useEffect(() => {
    const init = async () => {
      const ordersNumber = await getOrdersNumber();

      setOrderNumber(ordersNumber);
    };

    init();
  }, []);

  return (
    <Layout>
      <Section
        minHeight="calc(100vh - var(--footer-height))"
        contentFullWidth={
          <StatusMessageWrapper>
            <StatusMessage
              status={StatusMessageVariants.SUCCESS}
              title={`Wow! Your Wehead has got two-digit serial number: AAA00${
                orderNumber || "..."
              }. Congratulations!`}
              description="The receipt has been sent. We will contact you on the shipping details soon."
              buttonPreset={{
                message: "Go back",
                handleClick: () => navigate("/"),
              }}
            />
          </StatusMessageWrapper>
        }
      />
    </Layout>
  );
};

export default ThankYouPage;
