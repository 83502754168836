import { Theme } from "@emotion/react";

export const theme: Theme = {
  colors: {
    white: {
      700: "#EFEAEA",
      900: "#F4F5F9",
    },
    black: {
      700: "#131225",
      900: "#000000",
    },
    blue: {
      steel: "#7893A9",
      steel_dark: "#627484",
      700: "#78C8D6",
    },
    yellow: {
      wheat: "wheat",
    },
    pink: {
      700: "#AA8195",
      900: "#CF7B8C",
    },
    gradient: {
      linkman:
        "linear-gradient(270deg, #D77486 -2.47%, #9991A3 52.02%, #78C9D7 100%)",
    },
  },
};
