import styled from "@emotion/styled";

import { InputVariant } from "./interfaces";

export const InputStyled = styled.input<{
  variant: InputVariant;
  errorText?: string;
}>`
  --color: ${({ variant, errorText, theme }) =>
    errorText
      ? theme.colors.pink[900]
      : variant === InputVariant.LIGHT
      ? theme.colors.white[700]
      : theme.colors.black[900]};

  width: 100%;
  border: 1px solid
    ${({ variant, theme }) =>
      variant === InputVariant.LIGHT
        ? theme.colors.white[700]
        : theme.colors.black[900]};
  padding: 24px;
  color: ${({ variant, errorText, theme }) =>
    errorText
      ? theme.colors.pink[900]
      : variant === InputVariant.LIGHT
      ? theme.colors.white[700]
      : theme.colors.black[900]};
  transition-property: opacity, border-color;
  transition-duration: var(--transition-duration);
  font-size: 20px;

  :focus {
    border-color: ${({ theme }) => theme.colors.blue.steel_dark};
  }

  ::placeholder {
    color: ${({ variant, theme }) =>
      variant === InputVariant.LIGHT
        ? theme.colors.white[700]
        : theme.colors.black[900]}80;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.pink[900]};
  margin-top: 0.25rem;
  font-size: 0.93rem;
`;
