import { Global, css } from "@emotion/react";

export const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        :root {
          --max-width: 1120px;
          --transition-duration: 0.15s;
          --footer-height: 100px;
        }

        html {
          font-family: "Raleway", sans-serif;
          font-size: 16px;
          /* CSS custom property for the polyfill */
          --scroll-behavior: smooth;
          /* Normal CSS property for browsers with native support */
          scroll-behavior: smooth;
        }

        body {
          position: relative;
          margin: 0;
          padding: 0;
          min-height: 100vh;
          max-width: 100vw;

          &.not-scrollable {
            touch-action: none;
            overflow: hidden;
            -webkit-overflow-scrolling: auto;
          }
        }

        #root {
          height: 100%;
        }

        input,
        select,
        button {
          outline: none;
          border-radius: 18px;
          font-family: "Raleway", sans-serif;
          background-color: transparent;
          box-sizing: border-box;
        }

        input:-webkit-autofill,
        select:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
          -webkit-text-fill-color: #000;
        }

        input:-webkit-autofill:focus,
        select:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
          -webkit-text-fill-color: #000;
        }

        select {
          cursor: pointer;
        }

        button {
          cursor: pointer;
        }

        iframe {
          border: 0;
        }
      `}
    />
  );
};
