import React, { useEffect, useState } from "react";
import { IGalleryProductTable } from "./interfaces";
import { Bullet, BulletVariant, BulletSize } from "../../../../shared";

import {
  CarouselButton,
  CarouselStyled,
  GalleryCard
} from "./styles";

import { useMedia } from "react-use";

import { ReactComponent as ArrowRight } from "../../../../../assets/svg/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../../assets/svg/arrow-left.svg";

import { ReactComponent as ArrowRightG } from "../../../../../assets/svg/arrow-right-g.svg";
import { ReactComponent as ArrowLeftG } from "../../../../../assets/svg/arrow-left-g.svg";
export const GalleryProductTable: React.FC<IGalleryProductTable> = ({
  type,
  product,
  amount
}) => {
  const isTablet = useMedia("(max-width: 768px)");
  const [page, setPage] = useState<number>(0);
  const [labelStaus, setLabelStatus] = useState<string>(`${page} / ${amount}`);
  function handlePage(item: number) {
    setPage(item);
    setLabelStatus(`${item + 1} / ${amount}`);
  }


  useEffect(() => {
    const init = async () => {
    };

    init();
  }, []);


  return (
    <>
      <GalleryCard>
        <div className="title-form">Gallery</div>
        <div className="title-product">{product}</div>
        <div className="slider">
          {
            (type != '') ? (
              <CarouselStyled
                autoPlay
                infiniteLoop
                swipeable
                emulateTouch
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={65}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <CarouselButton
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{ left: isTablet ? '20px' : 'calc(50% - 200px)' }}
                    >
                      {isTablet ? <ArrowLeft style={{ marginRight: -4 }} /> : <ArrowLeftG style={{ marginRight: 0 }} />}
                    </CarouselButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <CarouselButton
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{ right: isTablet ? '20px' : 'calc(50% - 200px)' }}
                    >
                      {isTablet ? <ArrowRight style={{ marginRight: -4 }} /> : <ArrowRightG style={{ marginRight: 0 }} />}
                    </CarouselButton>
                  )
                }
                onChange={handlePage}
              >
                { (Array.from(Array(amount).keys()).map((i) => (
                  <div>
                    <img
                      style={{ objectFit: isTablet ? "cover" : "contain" }}
                      src={`/images/gallery/mobile/${type}/${i + 1}.png`}
                      alt=""
                      loading="lazy"
                      key={i}
                    />
                  </div>
                )))}
              </CarouselStyled>
            ) : (<div />)
          }

        </div>
        <div className="footer">
          <div className="slider-status">

            <Bullet variant={BulletVariant.STROKE} size={BulletSize.MINI}>
              {labelStaus}
            </Bullet>
          </div>
        </div>
      </GalleryCard>
    </>
  );
};
