import { PopUpProps } from "./interfaces";
import { PopUpWrapper } from "./styles";

import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";

export const PopUp: React.FC<PopUpProps> = ({
  children,
  show,
  closePopUp
}) => (
  <PopUpWrapper>
    {
      show && (
        <div className="pop-up">
          <div className="pop-up-close">
            <div className="pop-up__btn-close" onClick={closePopUp}>
              <CloseIcon />
            </div>
          </div>
          <div className="pop-up__content">
            {children}
          </div>
        </div>
      )
    }


  </PopUpWrapper>
);
