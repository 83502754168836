import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { IBlur } from "./interfaces";
import { Button } from "../../shared/Button";

export const IFrameStyled = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Blur = styled.div<IBlur>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 36px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  transition-property: opacity;
  transition-duration: var(--transition-duration);
  box-sizing: border-box;
  height: 100%;

  @media screen and (max-width: 768px) {
    
  }

  
  ${({ active }) =>
    active
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : css`
          opacity: 1;
          pointer-events: all;
        `}

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.34);
    backdrop-filter: blur(10px);
    z-index: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  max-width: var(--max-width);
  height: 100%;
  padding: 0 24px;
  margin: 0 auto;
  font-size: 36px;
  font-weight: 900;
  z-index: 1;

  & > * {
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    padding: 0 24px;
    font-size: 64px;
  }
`;

export const ButtonOpen = styled(Button)`
  width: calc(100% - 48px);
  margin-top: 12px;
  border-radius: 18px;
  z-index: 1;
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 21px;
    padding: 22px 24px;
    margin-top: 55px;
    text-transform: capitalize;
  }
  @media screen and (min-width: 768px) {
    width: unset;
    border-radius: 24px;
    background: #0EAECA; 
    font-size: 18px;
    font-style: normal;
    font-weight: 700;

    border: none;
    padding: 17px 89px 18px 89px;
  }
`;

export const ButtonClose = styled(Button)`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1000;

  @media screen and (min-width: 768px) {
    top: 48px;
    right: 48px;
  }
`;

export const ImageStub = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
`;

export const BackgroundText = styled.div`
  position: absolute;
  top: 46px;
  left: 50%;
  transform: translateX(-50%);
  color: #F4F5F9;
  font-size: 108px;
  font-family: Montserrat;
  font-weight: 900;
  line-height: 93.5px;
  opacity: 0.47999998927116394;
  z-index: 1;
  @media screen and (min-width: 768px) {
    top: 110px;
    font-size: 94px;
  }
`;

export const BadgeDemo = styled.div`
  position: absolute;
  font-size: 20px;
  display: inline-flex;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 56px;
  background: linear-gradient(270deg, #D77486 0%, #9991A3 53.18%, #78C9D7 100%);
  z-index: 1;
  @media screen and (min-width: 768px) {
    top: 66px;
  }
`;
