import React, { useEffect } from "react";
import { Section } from "../../../../widgets/Section";
import { useMedia } from "react-use";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { ReactComponent as ArrowRightG } from "../../../../../assets/svg/arrow-right-g.svg";
import { ReactComponent as ArrowLeftG } from "../../../../../assets/svg/arrow-left-g.svg";

import {
  CarouselButton,
  CarouselStyled,
  GalleryWrapper,
  GalleryContent
} from "./styles";


export const PhotoGallery: React.FC = () => {
  const isTablet = useMedia("(max-width: 768px)");



  useEffect(() => {
    const init = async () => {
    };

    init();
  }, []);


  return (
    <>
    <GalleryWrapper>
      <GalleryContent
        id="gallery"
      >        
        <CarouselStyled
            
            infiniteLoop
            showIndicators
            swipeable
            emulateTouch
            showThumbs
            showStatus={false}
            thumbWidth={isTablet ? 100 : 235}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={65}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <CarouselButton
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ left: !isTablet ? '56px' : '-8px', transform: `scale(${isTablet ? 0.5 : 1.0})`}}
                >
                  <ArrowLeftG style={{ marginLeft: -4 }} />
                </CarouselButton>
              )
            }
            renderArrowNext={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <CarouselButton
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ right: !isTablet ? '56px' : '-8px', transform: `scale(${isTablet ? 0.5 : 1.0})`}}
                >
                  <ArrowRightG style={{ marginRight: -4 }} />
                </CarouselButton>
              )
            }
          >
            {Array.from(Array(isTablet ? 14 : 13).keys()).map((i) => (
              <div>
                <img
                  style={{ objectFit: "cover" }}
                  src={`/images/gallery/${isTablet ? "vertical" : "horizontal"}/${i+1}.jpg`}
                  alt=""
                  loading="lazy"
                />
              </div>
            ))}
          </CarouselStyled>
      </GalleryContent>
      </GalleryWrapper>
    </>
  );
};
