import styled from "@emotion/styled";

import { Theme } from "../../../types";
import { Anchor } from "../Anchor";

export const LogoWrapper = styled(Anchor)<{ variant?: Theme }>`
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  user-select: none;

  :hover {
    text-decoration: none;
  }
`;
