import { request } from "http";

// import { GoogleSpreadsheet } from "google-spreadsheet";
export const appendRowToSpreadsheet = async (sheetId: string, values: any) => {
  await fetch(
    `https://wehead-api-landing.web.app/api/v0/requests/`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    },
  );
};

export const postWaitList = async (email: string, fullName: string) => {
  let requests = await fetch(
    `https://wehead-api-landing.web.app/api/v0/requests/`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, fullName, order: 2 })
    },
  )
  console.log(request);
};

  // Email notification
  // await fetch(
  //   String(process.env.REACT_APP_SPREADSEET_MACROS_EMAIL_NOTIFICATION),
  //   {
  //     redirect: "follow",
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "text/plain;charset=utf-8",
  //     },
  //     body: JSON.stringify({
  //       ...values
  //     }),
  //   }
  // );

  // result && window.alert("success");
  // } catch (e) {
  //   if (e instanceof Error) {
  //     window.alert(`Error: ${e.message}`);
  //   }
  // }
