import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { IBulletStatement } from "./interfaces";

export const BulletStatementWrapper = styled.div<
  IBulletStatement & { textShown: boolean }
>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
  align-items: flex-start;
  font-size: 1.5rem;

  ${({ textShown }) =>
    textShown &&
    css`
      margin: -4px 0;
      & > * {
        margin: 4px 0;
      }
    `}
`;

export const Text = styled.div`
  font-size: 1rem;
`;
