import { ButtonStyled } from "./styles";

export enum ButtonAppleSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  BIG = "BIG",
}

export enum ButtonAppleVariant {
  BUTTON = "BUTTON",
  TEXT = "TEXT",
}

export interface ButtonAppleProps {
  size: ButtonAppleSize;
  variant?: ButtonAppleVariant;
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void | Promise<void>;
  children?: React.ReactNode | React.ReactNode[];
}

export const ButtonApple: React.FC<ButtonAppleProps> = ({
  size,
  variant = ButtonAppleVariant.BUTTON,
  type,
  style,
  className,
  onClick,
  children,
}) => (
  <ButtonStyled
    size={size}
    variant={variant}
    onClick={onClick}
    type={type}
    style={style}
    className={className}
  >
    {children}
  </ButtonStyled>
);
