import styled from "@emotion/styled";
import { Loader } from "../../../../shared/Loader";

export const CardWaitList = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;

.card__top {
  background-color: #33333380;
  padding-top: 28px;
  @media screen and (min-width: 768px) {
    background-color: #00000080;
    padding-top: 47px;
  }
}

.card__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    padding: 20px 150px;
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box
  }
}
.card-indent {
  margin-top: auto;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.card__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
.card__product-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  padding-top:  9px;
  text-align: center;
}

.card__product-title--submit { 
  padding-top: 34px;
}

.card__preview {
  width: 100%;
  height: 275px;
  background-color: #33333380;
  display: flex;
  align-items: end;
  justify-content: center;

  @media screen and (min-width: 768px) {
    width: 390px;
    height: 508px;
  }
}
.card__preview img {
  height: 131%;
  position: relative;
  bottom: -71px;
  @media screen and (min-width: 768px) {
    height: 100%;
    bottom: 5px;
  }
}
.card-form {
  padding: 60px 24px 24px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media screen and (min-width: 768px) {
    box-sizing: border-box;
    padding: 60px 270px 24px 8px;
  }
  
}
.card__preview-label {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 39px;
  @media screen and (min-width: 768px) {
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
.card-form__input {
  margin-top: 24px;
}
.card-form__btn {
  margin-top: 46px;
}
.card-form__placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 46px;
  @media screen and (min-width: 768px) {
    margin-top: auto;
  }
}
.card-form__loader {
  margin-top: 24px;
  margin-bottom: 6px;
}
`;


export const BtnFormWrapper = styled.div`
  .btn {
    background: #0EAECA;
    border: 2px solid #0EAECA;
    border-radius: 18px;
    color: #F4F5F9;
    width: 100%;
    padding: 12px 24px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    box-sizing: border-box;
  }
  .btn.not-valid {
    color: #F4F5F9;
    background: transparent;
  }
  .btn-close {
    margin-top: 34px;
  }
`;

export const LoaderCentererd = styled(Loader)`
  display: flex !important;
  justify-content: center;
`;