import { useFormik } from "formik";
import { useCallback, useContext, useState } from "react";

import { ThemeContext } from "../../../../../contexts/ThemeContext";
import { appendRowToSpreadsheet } from "../../../../../utils/appendRowToSpreadsheet";
import { sendEventToGA } from "../../../../../utils/sendEventGA";
import { devFormValidationSchema } from "../../../../../validationSchemas/formValidation";
import { Button, ButtonSize } from "../../../../shared/Button";
import { Input, InputVariant } from "../../../../shared/Input";
import { FormStyled, LoaderCentererd } from "./styles";

interface GetApiFormValues {
  name: string;
  email: string;
}

const GetApiForm: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleCheckOutApiButtonClick = useCallback(() => {
    sendEventToGA(
      "check_out_api_click",
      "buttons_click",
      '"Check out" button click (API page)'
    );
  }, []);

  const handleSubmit = async (values: GetApiFormValues) => {
    setLoading(true);
    await appendRowToSpreadsheet(
      String(process.env.REACT_APP_DEV_SHEET_ID),
      values
    );
    setLoading(false);
    window.alert("Success!");
  };

  const formik = useFormik<GetApiFormValues>({
    initialValues: {
      name: "",
      email: "",
    },
    onSubmit: handleSubmit,
    validationSchema: devFormValidationSchema,
    validateOnChange: false,
  });

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      <Input
        id="name"
        name="name"
        placeholder="Name"
        value={formik.values.name}
        errorText={formik.errors.name}
        onChange={formik.handleChange}
        variant={InputVariant.DARK}
        required
      />
      <Input
        id="email"
        name="email"
        type="email"
        placeholder="Email"
        value={formik.values.email}
        errorText={formik.errors.email}
        onChange={formik.handleChange}
        variant={InputVariant.DARK}
        required
      />
      {isLoading ? (
        <LoaderCentererd />
      ) : (
        <Button
          size={ButtonSize.BIG}
          themeButton={themeContext.theme}
          // disabled={!formik.values.name || !formik.values.email}
          onClick={handleCheckOutApiButtonClick}
          type="submit"
        >
          Check out
        </Button>
      )}
    </FormStyled>
  );
};

export default GetApiForm;
