import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { ILayout } from "./interfaces";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { LayoutWrapper } from "./styles";

export const Layout: React.FC<ILayout> = ({ children }) => {
  const { pathname } = useLocation();
  const themeContext = useContext(ThemeContext);

  return (
    <LayoutWrapper variant={themeContext.theme}>
      <Header hideBeforeScroll={pathname === "/"} />
      {children}
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
