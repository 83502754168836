import * as yup from "yup";

export const formValidationSchema = yup.object().shape({
  email: yup.string().email("Invalid email"),
});

export const devFormValidationSchema = yup.object().shape({
  name: yup.string().min(2, "Name is too short"),
  email: yup.string().email("Invalid email"),
});
