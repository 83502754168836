import { memo } from "react";
import Icon from '@mdi/react';
import { mdiAccount, mdiInstagram, mdiYoutube } from '@mdi/js';
import { useMedia } from "react-use";

import { CREDENTIALS } from "../../../constants/credentials";
import { Anchor } from "../../shared/Anchor";
import { FooterContent, FooterWrapper, FooterContentLabel, Btn } from "./styles";


export const Footer: React.FC = () => {

  const isTablet = useMedia("(max-width: 768px)");
  function scheduleDemo() {
    const a = document.createElement("a");
    a.href = `https://calendly.com/go-wehead/30`;
    a.target = isTablet ? "_self" : "_blank";
    a.click();
    a.remove();
  }


  return (
    <FooterWrapper>
    <FooterContent>
      <FooterContentLabel>
        <div className="label">
          Learn More about what <br/>is possible with Wehead 
        </div>
        <Btn onClick={scheduleDemo}>Schedule Demo</Btn>
      </FooterContentLabel>
    </FooterContent>
    <FooterContent
      style={{gap: "16px"}}
    >
      <Anchor
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/@wehead"
      >
        <Icon path={mdiYoutube} size={1.5}/>
      </Anchor>
      <Anchor
        target="_blank"
        rel="noopener noreferrer"
        href="https://instagram.com/weheadcom"
      >
        <Icon path={mdiInstagram} size={1.5}/>
      </Anchor>
    </FooterContent>
    <FooterContent
      style={{gap: "64px"}}
    >
      <Anchor
        style={{width: "50%"}}
        href={`tel:${CREDENTIALS.phone}`}
      >
        {CREDENTIALS.phone}
      </Anchor>
      <Anchor
        style={{width: "50%"}}
        href={`mailto:${CREDENTIALS.email}`}
      >
        {CREDENTIALS.email}
      </Anchor>
    </FooterContent>
    <FooterContent
      style={{gap: "64px"}}
    >
      <Anchor
        style={{width: "50%"}}
      >
        &copy;2023 WEHEAD
      </Anchor>
      <Anchor
        target="_blank"
        rel="noopener noreferrer"
        style={{width: "50%"}}
        href="https://drive.google.com/file/d/1CzWqKlsUW8YFmp_zCs47NJ4_7txBifjy/view"
      >
        Terms of sales
      </Anchor>
    </FooterContent>
    </FooterWrapper>
  );
};

export default memo(Footer);
