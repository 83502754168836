import { Carousel } from "react-responsive-carousel";


import styled from "@emotion/styled";


export const GalleryCard = styled.div`
 .title-form {
  margin-top: 20px;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
 }
 .title-product {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-top  24px;
  text-align: center;
  padding-bottom: 24px;
 }
 .slider {
  height: calc(100vh - 75px);
 }
 .slider .slide div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 100px;
  height: 100%;
 }
 .slider .slide img {
  max-height: 100%;
  max-width: 100%;
 }
 .footer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;


  @media screen and (min-width: 768px) {
    bottom: 20px;
  }
 }
 .slider-status {
  
  text-align: center;
 }
`

export const CarouselButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 33px;
  top: auto;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  z-index: 1;
  padding: 0;
  @media screen and (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;



export const CarouselStyled = styled(Carousel)`
  margin-bottom: 16px;

  .carousel {
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
`;


