import { Theme } from "../../../types";
import { ButtonSize, ButtonVariant, IButton } from "./interfaces";
import { ButtonWrapper } from "./styles";

export const Button: React.FC<IButton> = ({
  size = ButtonSize.SMALL,
  themeButton = Theme.DARK,
  variant = ButtonVariant.OUTLINED,
  type = "button",
  children,
  ...props
}) => {
  return (
    <ButtonWrapper
      size={size}
      themeButton={themeButton}
      variant={variant}
      type={type}
      {...props}
    >
      {children}
    </ButtonWrapper>
  );
};
