import { useMedia } from "react-use";

import { ISection, SectionVariant } from "./interfaces";
import {
  Description,
  LeftColumnWrapper,
  RightColumnWrapper,
  SectionContent,
  SectionWrapper,
  Title,
} from "./styles";

export const Section: React.FC<ISection> = ({
  title,
  description,
  id,
  variant = SectionVariant.DARK,
  centeredVerticallyColumns = false,
  leftColumnsNum = 6,
  backgroundContent,
  backgroundTextProps,
  minHeight,
  contentFullWidth,
  reversed,
  children,
}) => {
  const isTablet = useMedia("(max-width: 768px)");
  let [leftColumn, rightColumn] = Array.isArray(children)
    ? children
    : [children, null];

  return (
    <SectionWrapper
      id={id}
      variant={variant}
      minHeight={minHeight}
      backgroundTextProps={backgroundTextProps}
    >
      {backgroundContent}
      <SectionContent
        extraTopPadding={
          isTablet ||
          Boolean(description) ||
          Boolean(leftColumn) ||
          Boolean(rightColumn) ||
          Boolean(children)
        }
      >
        {title && <Title>{title}</Title>}
        {(leftColumn || description) && (
          <LeftColumnWrapper
            leftColumnsNum={leftColumnsNum}
            centeredVerticallyColumns={centeredVerticallyColumns}
            isTablet={isTablet}
          >
            {description && <Description>{description}</Description>}
            {leftColumn && (!reversed ? leftColumn : rightColumn)}
            {isTablet && (
              <div style={{ marginTop: 36, flexGrow: 1, height: "100%" }}>
                {!reversed ? rightColumn : leftColumn}
              </div>
            )}
          </LeftColumnWrapper>
        )}
        {!isTablet && rightColumn && (
          <RightColumnWrapper
            leftColumnsNum={leftColumnsNum}
            centeredVerticallyColumns={centeredVerticallyColumns}
            isTablet={isTablet}
          >
            {!reversed ? rightColumn : leftColumn}
          </RightColumnWrapper>
        )}

        {contentFullWidth && (
          <div style={{ gridColumn: isTablet ? "1 / 6" : "1 / 13" }}>
            {contentFullWidth}
          </div>
        )}
      </SectionContent>
    </SectionWrapper>
  );
};
