import { DescriptListProps } from "./interfaces";
import { StyledDescriptList } from "./styles";

export const DescriptList: React.FC<DescriptListProps> = ({
  text,
  list
}) => (
  <StyledDescriptList>
    <div>{text}</div>
    <ul>
      {list && list.map((element, index) => (
        <li key={index} >{element}</li>
      ))}
    </ul>
  </StyledDescriptList>
);
