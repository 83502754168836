import styled from "@emotion/styled";

import { StatusMessageVariants } from "./interfaces";

export const PaymentStatusTitleWrapper = styled.div<{
  status: StatusMessageVariants;
}>`
  padding: 0 24px;
  font-size: 32px;
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  white-space: pre-wrap;

  color: ${({ status, theme }) =>
    status === StatusMessageVariants.FAILURE
      ? theme.colors.pink[900]
      : status === StatusMessageVariants.PROCESSING
      ? theme.colors.yellow.wheat
      : "inherit"};

  @media screen and (min-width: 768px) {
    font-size: 64px;
  }
`;

export const PaymentStatusDescription = styled.div`
  padding: 0 24px;
  font-size: 16px;
  margin-top: 8px;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;
