import React, { useEffect, useState, useMemo } from "react";
import { IFormWaitList, IFormData, IFormStatus } from "./interfaces";

import {
  CardWaitList,
  BtnFormWrapper,
  LoaderCentererd,
} from "./styles";

import { appendRowToSpreadsheet } from "../../../../../utils/appendRowToSpreadsheet";

import { useMedia } from "react-use";
import { InputForm } from "../../../../shared/InputForm";


export const FormWaitList: React.FC<IFormWaitList> = ({
  product,
  closeForm
}) => {
  const isTablet = useMedia("(max-width: 768px)");

  const [formStatus, setFormStatus] = useState<IFormStatus>({
    email: false,
    name: false
  })

  const [formData, setFormData] = useState<IFormData>({
    email: '',
    name: ''
  })
  const [formValid, setFormValid] = useState<boolean>(false)

  async function submitForm() {
    setFormValid(!Object.values(formStatus).every(el => el))
    if (Object.values(formStatus).every(el => el)) {
      setLoading(true);
      let values = {
        fullName: formData.name,
        email: formData.email,
        order: 2
      }

      try {
        await appendRowToSpreadsheet(
          String(process.env.REACT_APP_APPLICATIONS_SHEET_ID),
          values
        )
      } catch (err) {
        window.alert("Something went wrong. Please, try again");
      } finally {
        setStatusFormSubmit(true);
      }
      setLoading(false);
    }
  }

  const [isLoading, setLoading] = useState<boolean>(false);
  const [statusFormSubmit, setStatusFormSubmit] = useState<boolean>(false);


  function handleValidation(key: string, value: string) {
    let result = false
    if (key == 'email') {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(value)) {
        result = true
      }
    }
    if (key == 'name') {
      if (value.trim() != '') {
        result = true
      }
    }
    setFormStatus({ ...formStatus, [key]: result });
  }


  function setParamsForm(key: string, value: string) {
    setFormData({ ...formData, [key]: value });
    handleValidation(key, value);
    if (formValid) {
      setFormValid(!Object.values(formStatus).every(el => el));
    }
  }

  const formSubmit = useMemo(() => {
    return formValid ? Object.values(formStatus).every(el => el) : true;
  }, [formStatus, formValid]);

  useEffect(() => {
    console.log('use')
    const init = async () => {
    };

    init();
  }, []);


  return (
    <>
      <CardWaitList>
        {
          !statusFormSubmit ? (
            <div className="card__top">
              <div className="card__title">Coming Soon</div>
              <div className="card__product-title">{product}</div>
            </div>
          ) : (
            <div className="card__top">
              <div className="card__title">Congratulations</div>
              <div className="card__product-title card__product-title--submit">You are now on the<br /> Home Edition Waitlist</div>
            </div>
          )
        }

        <div className="card__content">
          <div className="card__preview">
            <img src="./images/gallery/product-table/waitlist.png"></img>
          </div>
          <div className="card-form">
            {
              !statusFormSubmit ? (
                <div className="card__preview-label">
                  Get notified ahead of our Global Launch!<br />Register your email to be added to our waitlist.
                </div>
              ) : (
                <div className="card__preview-label">
                  We will send an email to <br />
                  {formData.email} <br />
                  with updates about our launch <br />
                </div>

              )
            }
            {
              !statusFormSubmit ? (
                <>
                  <InputForm
                    placeholder="Full name"
                    status={formStatus.name}
                    value={formData.name}
                    errorShow={formValid}
                    onChange={(value: string) => setParamsForm('name', value)}
                  />
                  <InputForm
                    placeholder="Email"
                    status={formStatus.email}
                    value={formData.email}
                    errorShow={formValid}
                    onChange={(value: string) => setParamsForm('email', value)}
                  />
                  <div className="card-indent"></div>
                  {isLoading ? (
                    <div  className="card-form__loader">
                      <LoaderCentererd />
                    </div>
                  ) : (
                    <BtnFormWrapper className='card-form__btn' onClick={submitForm}>
                      <div className={`btn ${!formSubmit ? 'not-valid' : ''}`}>Add me to waitlist</div>
                    </BtnFormWrapper>
                  )}

                </>
              ) : (
                <>
                  <div className="card-indent"></div>
                  <BtnFormWrapper className='card-form__btn' onClick={closeForm}>
                    <div className='btn btn-form-close'>Close</div>
                  </BtnFormWrapper>
                </>
              )
            }

            <div className="card-form__placeholder">
              insert any terms or information that is relevant to the waitlist sign up process
            </div>
          </div>
        </div>

      </CardWaitList>
    </>
  );
};
