import { Carousel } from "react-responsive-carousel";
import styled from "@emotion/styled";

import { Loader } from "../../../../shared/Loader";
import OrderLimited from "../OrderLimited";
import { ButtonApple } from "../../../../shared/ButtonApple";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormStyled = styled.form`
  display: grid;
  row-gap: 16px;
`;

export const OrderLimitedMargin = styled(OrderLimited)`
  margin-left: 16px;
`;

export const LoaderCentererd = styled(Loader)`
  display: flex !important;
  justify-content: center;
`;

export const LoaderText = styled(Loader)`
  display: inline-block !important;
  transform: scale(0.5);
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 20px;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 24px;
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

export const SectionImageWrapper = styled.div`
  position: relative;
  height: 0;
  border-radius: 32px;
  padding-top: 88.63%;
  overflow: hidden;
  aspect-ratio: 528 / 468;
`;

export const SectionImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;

  // fallback
  @supports not (aspect-ratio) {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: auto;
    max-width: 100%;
    height: 100%;
  }
`;

export const SectionUl = styled.ul`
  margin: 4px 0 0 0;
`;

export const CarouselStyled = styled(Carousel)`
  margin-bottom: 16px;

  .carousel {
    border-radius: 32px;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }

  .thumbs-wrapper {
    margin: 16px 0;
  }

  .thumbs {
    padding: 0;
    margin: 0;
  }

  .thumb {
    height: 100px;
    border: none !important;
    filter: brightness(0.5);
    cursor: pointer;
    transition-duration: var(--transition-duration);

    img {
      height: 100%;
    }
  }

  .thumb:hover {
    filter: brightness(0.75);
  }

  .thumb.selected {
    filter: brightness(1);
  }
`;

export const CarouselButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  width: 64px;
  height: 64px;
  border: none;
  border-radius: 50%;
  background: ${({ theme }) => `${theme.colors.white[700]}57`};
  backdrop-filter: blur(10px);
  transition-duration: var(--transition-duration);
  z-index: 1;

  :hover {
    background: ${({ theme }) => `${theme.colors.white[700]}80`};
  }
`;

export const ButtonAppleMargin = styled(ButtonApple)`
  margin-top: 16px;
`;

export const Paragraph = styled.p`
  &:last-child {
    margin-bottom: 0;
  }
`;
