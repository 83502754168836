import { IAboutSection } from './interfaces'
import { useEffect } from "react";
import { useMedia } from "react-use";

import { DescriptList } from "../../../../shared/DescriptList";

import {
  AboutSectionWrapper,
  AboutSectionContainer,
  AboutSectionTop,
  AboutSectionFeatureList,
  AboutSectionFeature
} from "./styles";


export const AboutSection: React.FC<IAboutSection> = ({
}) => {
  const isTablet = useMedia("(max-width: 768px)");

  useEffect(() => {
    const init = async () => {
    };

    init();
  }, []);

  return (
    <>
      <AboutSectionWrapper>
        <AboutSectionContainer>
          <AboutSectionTop>
          <div className="about-section__title-wrapper">
            <div className="about-section__title">
              THIS IS<br/> WEHEAD
            </div>
          </div>
          <div className="about-section__description">
            <DescriptList
              text="First spatial video device featuring a unique face-shaped display and dynamic spatial movements, tailored for:"
              list={[
                "Immersive Virtual meetings (Call Mode)",
                "Pre-loaded “talking head” (Video Player Mode)",
                "Digital Human interactions (Virtual Agent Mode, beta)"
              ]}
            ></DescriptList>
            </div>
          </AboutSectionTop>
          <AboutSectionFeatureList>
            <AboutSectionFeature>
              <div className='title'>
                Full-size face
              </div>
              <div className='description'>
                Face features are precisely embodied by HD displays forming a head shape
              </div>
            </AboutSectionFeature>

            <AboutSectionFeature>
              <div className='title'>
                Head Gestures
              </div>
              <div className='description'>
                Guest head movements are mimicked by the Wehead's motors in real time to allow guest to focus on every aspect of communication
              </div>
            </AboutSectionFeature>

            <AboutSectionFeature>
              <div className='title'>
                Eye Contact
              </div>
              <div className='description'>
                Feels incredibly real due to the fact that camera is positioned right at the “eye's display”
              </div>
            </AboutSectionFeature>

            <AboutSectionFeature>
              <div className='title'>
                Spatial Audio
              </div>
              <div className='description'>
                Mic and speaker move together with the head which allows both guest and host to sense each other’s presence in the rooms
              </div>
            </AboutSectionFeature>

          </AboutSectionFeatureList>
        </AboutSectionContainer>
      </AboutSectionWrapper>
    </>
  );
};
