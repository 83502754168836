import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMedia } from "react-use";

import headBackground from "../../../assets/images/head~background.jpg";

import { ThemeContext } from "../../../contexts/ThemeContext";
import { redirect } from "../../../utils/redirect";
import { initColors } from "../../../utils/initColors";
import { Theme } from "../../../types";
import { ButtonSize } from "../../shared/Button";
import { GradientText } from "../../shared/GradientText";
import { BulletSize } from "../../shared/Bullet";
import Layout from "../../widgets/Layout/Layout";
import { Section, SectionVariant } from "../../widgets/Section";
import { BulletStatement } from "../../widgets/BulletStatement";
import { BulletStatementList } from "../../widgets/BulletStatementList";
import GetApiForm from "./components/GetApiForm";
import { GetApiButton } from "./main.styles";
import { sendEventToGA } from "../../../utils/sendEventGA";

interface IDevPage {
  theme: Theme;
}

const DevPage: React.FC<IDevPage> = ({ theme }) => {
  const { pathname, hash } = useLocation();
  const isTablet = useMedia("(max-width: 768px)");

  const [bulletSize, setBulletSize] = useState(BulletSize.DEFAULT);

  const handleGetApiButtonClick = useCallback(() => {
    redirect(`${pathname}#form`);
    sendEventToGA(
      "get_api_click",
      "buttons_click",
      '"Get The Api Access" button click'
    );
  }, [pathname]);

  useEffect(() => {
    document.title = "Wehead | API";
  }, []);

  useEffect(() => {
    initColors(theme);
  }, [theme]);

  useEffect(() => {
    if (hash) {
      redirect(`${pathname}${hash}`);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  useEffect(() => {
    setBulletSize(isTablet ? BulletSize.DEFAULT : BulletSize.BIG);
  }, [isTablet]);

  return (
    <ThemeContext.Provider value={{ theme }}>
      <Layout>
        <Section
          title={
            <>
              WE OFFER
              <br />
              YOU AN EARLY
              <br />
              ACCESS TO
              <br />
              <GradientText>OUR BETA API</GradientText>
            </>
          }
          description={
            "We encourage you to create with Wehead. Build your own apps with Wehead platform."
          }
          variant={SectionVariant.LIGHT}
          centeredVerticallyColumns
          minHeight="100vh"
          backgroundTextProps={{
            text: "API",
            centeredVertically: true,
            positionRight: -24,
            fontSize: 434,
          }}
        >
          <GetApiButton
            size={isTablet ? ButtonSize.SMALL : ButtonSize.BIG}
            onClick={() => window.scrollTo(0, document.body.scrollHeight)}
            themeButton={Theme.DARK}
          >
            Get The Api Access
          </GetApiButton>
        </Section>
        <Section
          title={
            <>
              <GradientText>A NEW ERA</GradientText>
              <br />
              IN ONLINE
              <br />
              EDUCATION
              <br />
              PROCESS
            </>
          }
          variant={SectionVariant.DARK}
          minHeight="100vh"
          backgroundTextProps={{
            backgroundImage: `url(${headBackground})`,
            positionRight: 0,
            opacity: 1,
          }}
          leftColumnsNum={8}
        >
          <BulletStatementList>
            <BulletStatement
              bulletText="1. Staying focused"
              text="Game-changer in online education. Wehead creates a shared space, providing an immersive effect for both, even with an only one device."
              bulletSize={bulletSize}
            />
            <BulletStatement
              bulletText="2. 360 degrees"
              text="Tutor is able to look around and to be fully involved into the student's space, bringing the efficiency of the online studying to a new level."
              bulletSize={bulletSize}
            />
            <BulletStatement
              bulletText="3. New form factor"
              text="Student feels tutor's physical presence thanks to 3D head-shaped displays and head gestures."
              bulletSize={bulletSize}
            />
            <BulletStatement
              bulletText="4. Real-time"
              text="Freedom of head movement without a delay. Just a natural connection in real-time educational dialogue."
              bulletSize={bulletSize}
            />
            <BulletStatement
              bulletText="5. High-quality audio "
              text="Professional external mic gives a crystal-clear sound, which is perfectly suited for speech centered studies or music lessons."
              bulletSize={bulletSize}
            />
          </BulletStatementList>
        </Section>
        <Section
          title={
            <div style={{ marginTop: isTablet ? "60px" : "unset" }}>
              GET THE API
            </div>
          }
          description={
            "Leave us your contacts\nand we will send you\ndetailed information."
          }
          id="form"
          minHeight="calc(100vh - var(--footer-height))"
          leftColumnsNum={5}
          centeredVerticallyColumns
          variant={SectionVariant.LIGHT}
          backgroundTextProps={{
            text: "WE \\A HEAD",
            centeredVertically: true,
          }}
        >
          <GetApiForm />
        </Section>
      </Layout>
    </ThemeContext.Provider>
  );
};

export default DevPage;
