import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { IBulletStatements } from "./interfaces";

export const BulletStatementsWrapper = styled.div<IBulletStatements>`
  ${({ mobileHideText }) =>
    mobileHideText
      ? css`
          display: flex;
          flex-wrap: wrap;
          margin: calc(-12px / 2) !important;

          & > * {
            margin: calc(12px / 2) !important;
          }
        `
      : css`
          display: grid;
          gap: 46px;
          grid-template-columns: 1fr;
        `}

  @media screen and (min-width: 768px) {
    display: grid;
    gap: 8px;
    row-gap: 12px;
    column-gap: 32px;
    grid-template-columns: repeat(2, fit-content(100%));
  }
`;
