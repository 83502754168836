import styled from "@emotion/styled";


export const AboutSectionWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-top: 120px;
`;

export const AboutSectionContainer = styled.div`
  max-width: 1138px;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%
`;

export const AboutSectionTop = styled.div`
display: flex;

.about-section__title-wrapper {
  display: flex;
  justify-content: center;
}

.about-section__title {
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: #EFEAEA;
  text-transform: uppercase;
  margin-top: 12px;
  margin-right: 112px;
  
}
.about-section__description {
  width: 100%;
}
`

export const AboutSectionFeatureList = styled.div`
display: flex;
gap: 30px;
justify-content: space-between;
margin-top: 110px;
`

export const AboutSectionFeature = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 232px;

.title {
  color: #0EAECA;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.description {
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}
`

