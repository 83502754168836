export enum StatusMessageVariants {
  SUCCESS = "success",
  PROCESSING = "processsing",
  FAILURE = "failure",
}

export interface ButtonPreset {
  message: string;
  handleClick(): void;
}

export interface StatusMessageProps {
  title: string;
  description: string;
  status?: StatusMessageVariants;
  buttonPreset?: ButtonPreset;
  className?: string;
}
