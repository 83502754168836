import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { BulletSize, BulletVariant, IBullet } from "./interfaces";

export const BulletWrapper = styled.div<IBullet>`
  --border-width:  ${(props) => (props.size === BulletSize.MINI ? "1px" : "2px")};
  --border-radius: 56px;

  position: relative;
  display: inline-block;
  font-size: ${(props) => (props.size === BulletSize.BIG ? "24px" : props.size === BulletSize.MINI ? "12px" : "20px")};
  white-space: nowrap;

  ${(props) =>
    props.variant === BulletVariant.TEXT &&
    css`
      background: ${props.theme.colors.gradient.linkman};
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 700;
    `}
  ${(props) =>
    props.variant === BulletVariant.FILLED &&
    css`
      border-radius: var(--border-radius);
      color: ${props.theme.colors.white[900]};
      background: ${props.theme.colors.gradient.linkman};
    `}
    ${(props) =>
    props.variant === BulletVariant.STROKE &&
    css`
      padding: ${props.size === BulletSize.MINI ? "6px 11px" : "8px 16px"};
      background: ${props.theme.colors.gradient.linkman};
      background-clip: text;
      text-fill-color: transparent;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        border-radius: var(--border-radius);
        padding: var(--border-width);
        background: ${props.theme.colors.gradient.linkman};
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    `};


    ${(props) =>
      props.sizeDesctop === BulletSize.BIG &&
      css`
      @media screen and (min-width: 768px) {
        font-size: 14px;
        padding: 11px 24px;
      }
      `};

    ${(props) =>
        props.hoverStyle === true &&
        css`
        @media screen and (min-width: 768px) {
          :hover {
            --border-width: 2px;
          }
        }
        
        `};
`;
