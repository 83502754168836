import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Button } from "../../shared/Button";

export const GetApiButton = styled(Button)`
  ${({ theme }) => css`
    margin: 0 auto;
    background-color: ${theme.colors.black[900]};
    border-color: ${theme.colors.black[900]};

    :hover {
      border-color: ${theme.colors.blue.steel};
    }

    @media screen and (min-width: 750px) {
      margin: unset;
    }
  `}
`;
