import { BulletSize, BulletVariant, IBullet } from "./interfaces";
import { BulletWrapper } from "./styles";

export const Bullet: React.FC<IBullet> = ({
  variant = BulletVariant.TEXT,
  size = BulletSize.DEFAULT,
  sizeDesctop = BulletSize.DEFAULT,
  className,
  children,
  hoverStyle
}) => (
  <BulletWrapper variant={variant} size={size} sizeDesctop={sizeDesctop} className={className} hoverStyle={hoverStyle}>
    {children}
  </BulletWrapper>
);
