import { PreOrderLimitedWrapper } from "./styles";

interface IPreOrderLimited {
  className?: string;
}

const OrderLimited: React.FC<IPreOrderLimited> = ({ className, ...props }) => {
  return (
    <PreOrderLimitedWrapper className={className} {...props}>
      <div style={{ fontSize: 20, fontWeight: 700 }}>LIMITED EDITION</div>
      <div style={{ fontSize: 16 }}>of 100 units</div>
    </PreOrderLimitedWrapper>
  );
};

export default OrderLimited;
