import { useState } from "react";
import Collapsible, { CollapsibleProps } from "react-collapsible";

import { ReactComponent as PlusIcon } from "../../../assets/svg/plus.svg";
import { ReactComponent as MinusIcon } from "../../../assets/svg/minus.svg";

export const Accordion: React.FC<CollapsibleProps> = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        fontSize: 16,
        margin: "-1px 0",
        padding: "12px 0",
        borderTop: "none",
        borderBottom: "1px solid #817e7e",
        width: "100%"
      }}
    >
      <Collapsible
        open={open}
        transitionTime={150}
        triggerStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
        onTriggerOpening={() => setOpen(true)}
        onTriggerClosing={() => setOpen(false)}
        {...props}
        trigger={
          <>
            <span style={{ paddingRight: 16 }}>{props.trigger}</span>
            <span>{open ? <MinusIcon /> : <PlusIcon />}</span>
          </>
        }
      >
        <div style={{ opacity: 0.54 }}>{props.children}</div>
      </Collapsible>
    </div>
  );
};
