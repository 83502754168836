import styled from "@emotion/styled";


export const TabProduct = styled.a`
  display:inline-block;
  position:relative;
  border-radius:3px;
  text-decoration:none;
  padding:.5em;
  transition:all .5s;
  color: #fff;
`;

export const PreviewProduct = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  width 100%;
  height: 603px;
  background: #33333380;
  position: relative;
  @media screen and (min-width: 768px) {
    width: 44%;
    height: 574px;
  }
  img {
    max-width: 100%
  }
`
export const BtnPreview = styled.div`
  position: absolute;
  background: #000000;
  border-radius: 24px;
  border: 1px solid #000000;
  padding: 6px 23px;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  transition: 0.2s;
  @media screen and (min-width: 768px) {
    bottom: 26px;
    padding: 10px 24px 11px 24px;
    :hover {
      border-color: #ffffff;
      background-color: #090909;
    }
  }
`

export const CardContentProduct = styled.div`
  position: relative;
  padding: 43px 20px 103px 20px;
  background: #000;
  @media screen and (min-width: 768px) {
    width: 45%;
    box-sizing: border-box;
    min-width: 400px;
    padding: 13px 20px 103px 1px;
  }
  .top {
    display: flex;
    justify-content: space-between;
  }
  .name {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    @media screen and (min-width: 768px) {
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 56px;
    }
  }
  .price__container {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
  .price {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-right: 23px;
    
  }
  .badge {
    margin-right: 13px;
    margin-top: 2px;
  }
`

export const ProductContent = styled.div`
margin-top: 24px;
position: relative;
@media screen and (min-width: 768px) {
  margin-top: 29px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
b {
  font-weight: 600
  font-weight: 500;
}
.more {
  display: none
}
.more.show {
  display: block
}

ul {
  padding-left: 20px;
  margin-top: 18px;
  list-style-type: none;
}
li {
  position: relative;
}
li:after {
  content: "";
  position: absolute;
  left: -8px;
  top: 9px;
  border-radius: 50%;
  width: 2px;
  height: 2px;
  background-color: #fff
}
.divider {
  position:relative;
  width: 100%
}
.divider:after {
  content: '';
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  border: 1px solid #9B90A2;
  width: 50%;

}
`

export const ProductCardActions = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
margin-top: 39px;
@media screen and (min-width: 768px) {
  max-width: 342px;

  .btn:hover {
    border-color: ${(props) => props.theme.colors.blue.steel};
    background-color: ${(props) => props.theme.colors.blue.steel};
  }
  .btn.btn--outliner:hover {
    border-color: ${(props) => props.theme.colors.blue.steel};
    background-color: ${(props) => props.theme.colors.blue.steel};
  }
}

.btn {
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #EFEAEA;
  padding: 11px 26px 11px 26px;
  border: 1px solid #0EAECA;
  background-color: #0EAECA;
  border-radius: 24px;  
  text-align: center;
  cursor: pointer;
  transition: 0.2s
}
.btn.btn--outliner {
  background-color: transparent;
}
.btn:not(.btn--outliner) {

}
`

export const ProductCard = styled.div`
  padding-bottom: 43px;
  position: relative;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const OrderSectionTableTop = styled.div`
  padding: 70px 0 18px 0;
  margin-top: -46px;
  position: sticky;
  top: 0px;
  z-index: 2;
  @media screen and (min-width: 768px) {
    padding: 8px 0px 0px;
    margin-top: 16px;
    z-index: 4;
}
  }
  .section__title {
    margin-bottom: 32px;
  }
`

export const ProductList = styled.div`
  margin-top: 172px;
`

export const OrderSectionTableTitle = styled.div`
  position: relative;
  z-index: 3;

  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  width: 100%;
  display: block;
  text-align: center;
  
  @media screen and (min-width: 768px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 56px;
    text-transform: uppercase;
  }
  
`

export const OrderSectionTableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OrderSectionTableContainer = styled.div`
  position: relative;
  max-width: 1138px;
  padding: 0 10px;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    padding-top: 344px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
export const TabMenuProduct = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #000000a3;
  backdrop-filter: blur(10px);
  padding: 0px 24px 0px 24px;
  @media screen and (min-width: 768px) {
    background: transparent;
    backdrop-filter: none;
  }
`;



