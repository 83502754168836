// import { GoogleSpreadsheet } from "google-spreadsheet";

export const getOrdersNumber = async () => {
  const resp = await fetch(
    `https://link-srv-ule2kkd6ca-lm.a.run.app/api/v0/requests/count`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Cache-Control': 'no-cache' },
    },
  )
  return resp.json()
};
