import { useMedia } from "react-use";

import { IDemoContent } from "./interfaces";
import { ButtonSize } from "../../shared/Button";
import {
  Blur,
  Content,
  IFrameStyled,
  ButtonOpen,
  ButtonClose,
  ImageStub,
  BackgroundText,
  BadgeDemo
} from "./styles";

export const DemoContent: React.FC<IDemoContent> = ({
  title,
  src,
  imageStubUrl,
  active,
  toggleActive,
  children,
  buttonText,
  badge,
  backgroundTextProps
}) => {
  const isTablet = useMedia("(max-width: 768px)");

  return (
    <>
      {active && (
        <IFrameStyled
          title={title}
          src={src}
          allow="accelerometer; camera; encrypted-media; display-capture; geolocation; gyroscope; microphone; midi; clipboard-read; clipboard-write; web-share"
          sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-downloads allow-presentation"
        />
      )}
      {active && <ButtonClose onClick={toggleActive}>Exit</ButtonClose>}
      {!active && <ImageStub src={imageStubUrl} alt="" />}
      <Blur active={active}>
        <BackgroundText>{backgroundTextProps}</BackgroundText>
        <BadgeDemo>{badge}</BadgeDemo>
        <Content>
          {children}
          <ButtonOpen
            size={isTablet ? ButtonSize.SMALL : ButtonSize.BIG}
            onClick={toggleActive}
          >
            { buttonText || "Start Demo" }
          </ButtonOpen>
        </Content>
      </Blur>
    </>
  );
};
