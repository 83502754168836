import styled from "@emotion/styled";

import { AnchorProps } from "./interfaces";

export const StyledAnchor = styled.a<AnchorProps>`
  color: #ffffff;
  font-size: inherit;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
