import { IBulletStatement } from "./interfaces";
import { Bullet, BulletSize, BulletVariant } from "../../shared/Bullet";
import { BulletStatementWrapper, Text } from "./styles";

export const BulletStatement: React.FC<IBulletStatement> = ({
  bulletText,
  text,
  bulletSize = BulletSize.DEFAULT,
  bulletVariant = BulletVariant.STROKE,
  reverse = false,
}) => {
  return (
    <BulletStatementWrapper reverse={reverse} textShown={!!text}>
      <Bullet size={bulletSize} variant={bulletVariant}>
        {bulletText}
      </Bullet>
      {text && <Text>{text}</Text>}
    </BulletStatementWrapper>
  );
};
