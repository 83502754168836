import { useCallback, useContext, useEffect, useRef } from "react";
import { useMedia } from "react-use";

import { IHeader } from "./interfaces";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { redirect } from "../../../utils/redirect";
import { Logo } from "../../shared/Logo";
import { HeaderWrapper, HeaderContent, ButtonsWrapper } from "./styles";
import { sendEventToGA } from "../../../utils/sendEventGA";
import { Button, ButtonVariant } from "../../shared/Button";

export const Header: React.FC<IHeader> = ({ hideBeforeScroll = false }) => {
  const isTablet = useMedia("(max-width: 768px)");
  const headerRef = useRef<HTMLHeadingElement>(null);
  const themeContext = useContext(ThemeContext);

  function scheduleDemo() {
    const a = document.createElement("a");
    a.href = `https://calendly.com/go-wehead/30/`;
    a.target = isTablet ? "_blank" : "_blank";
    a.click();
    a.remove();
  }

  useEffect(() => {
    if (hideBeforeScroll) {
      const handleScroll = () => {
        headerRef.current?.classList.add("visible");
      };

      window.addEventListener("scroll", handleScroll, { once: true });
    }
  }, [hideBeforeScroll]);

  const handleRedirectButtonClick = useCallback(
    (url: string) => () => {
      sendEventToGA(
        "pre_order_click",
        "buttons_click",
        '"Pre-order" header button click'
      );
      redirect(url);
    },
    []
  );

  return (
    <HeaderWrapper
      variant={themeContext.theme}
      hideBeforeScroll={hideBeforeScroll}
      ref={headerRef}
    >
      <HeaderContent>
        <Logo />
        <ButtonsWrapper>


          <Button
            style={{ borderRadius: !isTablet ? '24px' : '12px' }}
            variant={isTablet ? undefined : ButtonVariant.FILL}
            themeButton={themeContext.theme}
            onClick={() => scheduleDemo()}
          >
            Demo
          </Button>
        </ButtonsWrapper>
      </HeaderContent>
    </HeaderWrapper>
  );
};
