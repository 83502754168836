import styled from "@emotion/styled";

import { DescriptListProps } from "./interfaces";

export const StyledDescriptList = styled.div<DescriptListProps>`
  div {
    margin-bottom: 36px;
    font-size: 1rem;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
      margin-bottom: 18px;
    }
    @media screen and (min-width: 768px) {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }
  ul {
    padding-left: 20px;
    list-style-type: none;
    @media screen and (max-width: 768px) {
      padding-left: 24px;
      margin-bottom: 0;
    }
    @media screen and (min-width: 768px) {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
    
    
  }
  li {
    position: relative;
  }
  li:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 9px;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    background-color: #fff
  }
  @media screen and (min-width: 768px) {
    div {
      margin-top: 24px;
      font-size: 1.5rem;
    }
    li:after {
      left: -14px;
    top: 14px;
    width: 4px;
    height: 4px;
    }
  }
`;
