import { IInput, InputVariant } from "./interfaces";
import { ErrorText, InputStyled } from "./styles";

export const Input: React.FC<IInput> = ({
  variant = InputVariant.LIGHT,
  errorText,
  ...props
}) => {
  return (
    <div>
      <InputStyled variant={variant} errorText={errorText} {...props} />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
};
