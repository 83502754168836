import { IBulletStatements } from "./interfaces";
import { BulletStatementsWrapper } from "./styles";

export const BulletStatementList: React.FC<IBulletStatements> = ({
  mobileHideText = false,
  children,
}) => {
  return (
    <BulletStatementsWrapper mobileHideText={mobileHideText}>
      {children}
    </BulletStatementsWrapper>
  );
};
