import { Carousel } from "react-responsive-carousel";
import styled from "@emotion/styled";

export const CarouselStyled = styled(Carousel)`
  margin-bottom: 179px;
  width: 100%;

  .carousel-slider {
    width: calc(100% - 120px);
    height: 504px;
    @media screen and (max-width: 768px) {
      height: 360px;
    }
  }
  .carousel .slide {
    height: 504px;
    padding: 1px;
    @media screen and (max-width: 768px) {
      height: 360px;
    }
  }
  .carousel .slide div {
    height: 100%
  }
  .carousel .slide img {
    max-height: 100%;
  }
  

  .carousel {
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
  .carousel .slider-wrapper {
    width: calc( 100% - 232px);
    @media screen and (max-width: 768px) {
      width: 88%;
    }
  }

  .thumbs-wrapper {
    margin-top: 28px;
    margin-left: 116px;
    margin-right: 116px;
    @media screen and (max-width: 768px) {
      margin-top: 10px;
      margin-left: 6%;
      margin-right: 6%;
    }
  }
  .thumbs-wrapper .control-arrow {
    display: none;
  }

  .thumbs {
    padding: 0;
  }

  .thumb {
    height: 110px;
    border: none !important;
    filter: brightness(0.5);
    cursor: pointer;
    padding-right: 30px;
    margin: 0;
    transition-duration: var(--transition-duration);
    @media screen and (max-width: 768px) {
      height: 50px;
    }

    img {
      height: 100%;
    }
  }

  .thumb:hover {
    filter: brightness(0.75);
  }

  .thumb.selected {
    filter: brightness(1);
  }

  .control-dots {

  }
  .control-dots .dot {
    display: none;
  }
  .control-dots .dot:nth-of-type(1) {
    display: inline-block;
  }
  .control-dots .dot:nth-of-type(2) {
    display: inline-block;
  }
  .control-dots .dot:nth-of-type(3) {
    display: inline-block;
  }
  .control-dots .dot:nth-last-of-type(1) {
    display: inline-block;
  }
  .control-dots .dot:nth-last-of-type(2) {
    display: inline-block;
  }
  .control-dots .dot:nth-last-of-type(3) {
    display: inline-block;
  }
  .control-dots .dot.selected {
    display: inline-block;
  }
  .carousel .thumbs {
    margin: 0;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  
`;

export const GalleryContent = styled.div`
  max-width: 1138px;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`
export const GalleryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const CarouselButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50%);
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  transition-duration: var(--transition-duration);
  opacity: 0.8;
  z-index: 1;

  :hover {
    opacity: 1;
  }
`;

