import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Theme } from "../../../types";

import { ILayout } from "./interfaces";

export const LayoutWrapper = styled.div<ILayout & { variant: Theme }>`
  ${({ theme, variant }) =>
    variant === Theme.DARK
      ? css`
          color: ${theme.colors.white[700]};
          background-color: ${theme.colors.black[900]};

          a {
            color: ${theme.colors.white[700]};
          }
        `
      : css`
          color: ${theme.colors.black[900]};
          background-color: ${theme.colors.white[700]};

          a {
            color: ${theme.colors.white[900]};
          }
        `}
`;
