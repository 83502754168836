import { theme as themeConstant } from "../constants/theme";
import { Theme } from "../types";

export const initColors = (theme: Theme) => {
  const color =
    theme === Theme.DARK
      ? themeConstant.colors.black[900]
      : themeConstant.colors.white[700];

  const html = document.getElementsByTagName("head")[0];
  const body = document.body;
  var meta = document.createElement("meta");
  meta.name = "theme-color";
  meta.content = color;

  html.appendChild(meta);
  html.style.background = color;
  body.style.background = color;
};
