import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Theme } from "../types";
import DevPage from "./pages/DevPage/DevPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import ThankYouPage from "./pages/ThankYouPage";
import { GlobalStyles } from "./index.styles";

const App: React.FC = () => (
  <BrowserRouter>
    <GlobalStyles />
    <Routes>
      <Route path="/" element={<LandingPage theme={Theme.DARK} />} />
      <Route path="/dev" element={<DevPage theme={Theme.LIGHT} />} />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);

export default App;
