import { IOption } from "./types";

import head from "../../../assets/images/head.jpg";
import headPro from "../../../assets/images/head-pro.jpg";

export const OPTIONS: Array<IOption> = [
  {
    id: "0",
    title: "Wehead Founders Edition ",
    shortTitle: "Wehead FE",
    description: "Limited time offer.\nThe retail price is $2550",
    shortInfo:
      "Provides the essentials of the in-person meeting, such as eye contact, full size facial features, expressions, as well as spatial speech and head gestures.",
    price: "$2299",
    url: "https://buy.stripe.com/4gwdT7ciE4Qk1yw4gu" as string,
    imageUrl: head,
  },
  {
    id: "1",
    title: "Wehead Pro",
    shortTitle: "Wehead Pro",
    shortInfo:
      "Enhances sensory perception. It is made with the most advanced avatar technology. Pro is made with premium finishing & materials and comes with 10 hrs battery life.",
    price: "$4555",
    url: process.env.REACT_APP_PAYMENT_URL_WEHEAD_PRO as string,
    imageUrl: headPro,
  },
];