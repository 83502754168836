import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { IColumn, ISection, SectionVariant } from "./interfaces";

export const SectionWrapper = styled.div<ISection>`
  position: relative;
  width: 100%;
  min-height: ${(props) => props.minHeight};
  background-color: ${(props) =>
    props.variant === SectionVariant.DARK
      ? props.theme.colors.black[900]
      : props.theme.colors.white[700]};
  color: ${(props) =>
    props.variant === SectionVariant.DARK
      ? props.theme.colors.white[700]
      : props.theme.colors.black[900]};
  box-sizing: border-box;
  word-wrap: break-word;
  overflow: hidden;
  z-index: 0;

  .background-content {
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }

  ${({ variant, theme, backgroundTextProps }) =>
    backgroundTextProps &&
    css`
      &::before {
        content: "${backgroundTextProps.text}";
        position: absolute;
        ${backgroundTextProps.centeredVertically
          ? css`
              top: 50%;
              transform: translateY(-50%);
            `
          : css`
              bottom: -20px;
            `}

        right: ${backgroundTextProps.positionRight ?? -59}px;
        ${backgroundTextProps.backgroundImage
          ? css`
              background-image: ${backgroundTextProps.backgroundImage};
              background-size: contain;
              background-repeat: no-repeat;
              background-position: right;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            `
          : undefined}
        font-family: "Montserrat", sans-serif;
        font-weight: 900;
        font-size: ${backgroundTextProps.fontSize || 284}px;
        line-height: 85.4%;
        white-space: pre-wrap;
        color: ${variant === SectionVariant.DARK
          ? theme.colors.white[700]
          : theme.colors.black[900]};
        opacity: ${backgroundTextProps.opacity || 0.08};

        @media screen and (max-width: 768px) {
          display: ${!backgroundTextProps.showTablet ? "none" : undefined};
          font-size: ${backgroundTextProps.fontSizeTablet || 110}px;
          right: ${backgroundTextProps.positionRightTablet ?? -25}px;
          bottom: ${!backgroundTextProps.centeredVertically
            ? "-8px"
            : undefined};
        }
      }
    `}
`;

export const SectionContent = styled.div<{
  extraTopPadding: boolean;
}>`
  position: relative;
  display: grid;
  min-height: inherit;
  min-width: inherit;
  column-gap: 22px;
  grid-template-columns: repeat(5, 1fr);
  max-width: var(--max-width);
  height: 100%;
  padding: ${({ extraTopPadding }) =>
    extraTopPadding ? "54px 24px 54px" : "54px 24px"};
  margin: 0 auto;
  box-sizing: border-box;
  pointer-events: none;

  & > * > * {
    pointer-events: all;
  }

  @media screen and (min-width: 768px) {
    padding: 84px 24px;
    column-gap: 32px;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const Title = styled.div`
  grid-column: 1 / 6;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 900;
  white-space: pre-wrap;

  @media screen and (max-width: 768px) {
    margin-bottom: 26px;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1 / 13;
    font-size: 64px;
  }

  @media screen and (min-width: 1280px) {
    white-space: nowrap;
  }
`;

export const Description = styled.div`
  margin-bottom: 36px;
  font-size: 1rem;
  white-space: pre-wrap;
  @media screen and (max-width: 768px) {
    margin-bottom: 9px;
  }


  @media screen and (min-width: 768px) {
    margin-top: 24px;
    font-size: 1.5rem;
  }
`;

export const LeftColumnWrapper = styled.div<IColumn>`
  display: flex;
  flex-direction: column;
  ${({ leftColumnsNum, centeredVerticallyColumns, isTablet }) => css`
    justify-content: ${centeredVerticallyColumns ? "center" : "unset"};
    grid-column: ${isTablet ? "1 / 6" : `1 / ${leftColumnsNum + 1}`};
  `}
`;

export const RightColumnWrapper = styled.div<IColumn>`
  display: flex;
  flex-direction: column;
  ${({ leftColumnsNum, centeredVerticallyColumns }) => css`
    justify-content: ${centeredVerticallyColumns ? "center" : "unset"};
    grid-column: ${leftColumnsNum + 1} / 13;
  `}
`;
