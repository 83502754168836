import React, { useEffect, useState, useMemo, useCallback } from "react";
import { IFormBuy, IForm, IFormStatus } from "./interfaces";
import { useFormik } from "formik";
import { OPTIONS } from "../../constants";
import { formValidationSchema } from "../../../../../validationSchemas/formValidation";
import { appendRowToSpreadsheet } from "../../../../../utils/appendRowToSpreadsheet";

import { ReactComponent as IconInputMinus } from "../../../../../assets/svg/input-minus.svg";
import { ReactComponent as IconInputPlus } from "../../../../../assets/svg/input-plus.svg";
import { sendEventToGA } from "../../../../../utils/sendEventGA";

import {
  CardWaitList,
  BtnFormWrapper,
  LoaderCentererd
  // InputAmount
} from "./styles";

import { useMedia } from "react-use";
import { InputForm } from "../../../../shared/InputForm";


export const FormBuy: React.FC<IFormBuy> = ({
  product,
  price
}) => {
  const isTablet = useMedia("(max-width: 768px)");
  const [isLoading, setLoading] = useState<boolean>(false);

  const [formStatus, setFormStatus] = useState<IFormStatus>({
    email: false,
    name: false
  })

  const [formData, setFormData] = useState<IForm>({
    email: '',
    name: '',
    amount: 1
  })
  const [formValid, setFormValid] = useState<boolean>(false)

  interface OrderFormValues {
    email: string;
    order: string;
  }

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const currOption = OPTIONS.find((option) => option.id === values.order);

    try {
      // await appendRowToSpreadsheet(
      //   String(process.env.REACT_APP_APPLICATIONS_SHEET_ID),
      //   values
      // );
      const a = document.createElement("a");
      a.href = `${currOption?.url}?prefilled_email=${values.email}` || "";
      a.target = isTablet ? "_self" : "_blank";
      a.click();
      a.remove();
    } catch (err) {
      window.alert("Something went wrong. Please, try again");
    } finally {
      setLoading(false);
    }
  };

  // const formik = useFormik<OrderFormValues>({
  //   initialValues: {
  //     email: "",
  //     order: OPTIONS[0].id,
  //   },
  //   onSubmit: handleSubmit,
  //   validationSchema: formValidationSchema,
  //   validateOnChange: false,
  // });

  // const currOption = useMemo(
  //   () => OPTIONS.find((option) => option.id === formik.values.order),
  //   [formik.values.order]
  // );


  function submitForm() {
    setFormValid(!Object.values(formStatus).every(el => el));
    if (Object.values(formStatus).every(el => el)) {
      handleCheckOutButtonClick();
      handleSubmit({ email: formData.email, order: OPTIONS[0].id })
    }
  }

  function handleValidation(key: string, value: string) {
    let result = false
    if (key == 'email') {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(value)) {
        result = true
      }
    }
    if (key == 'name') {
      if (value.trim() != '') {
        result = true
      }
    }
    setFormStatus({ ...formStatus, [key]: result });
  }

  function setAmount(value: string) {
    setFormData({ ...formData, amount: Number(value) });
  }

  function setParamsForm(key: string, value: string) {
    setFormData({ ...formData, [key]: value });
    handleValidation(key, value);
    if (formValid) {
      setFormValid(!Object.values(formStatus).every(el => el));
    }
  }

  const formSubmit = useMemo(() => {
    return formValid ? Object.values(formStatus).every(el => el) : true;
  }, [formStatus, formValid]);

  const totalPrice = useMemo(() => {
    return (price && formData.amount) ? (Number(price) * formData.amount) : 0;
  }, [price, formData.amount]);


  const handleCheckOutButtonClick = useCallback(() => {
    sendEventToGA(
      "check_out_click",
      "buttons_click",
      '"Check out" button click'
    );
  }, []);


  useEffect(() => {
    console.log('use')
    const init = async () => {
    };

    init();
  }, []);


  return (
    <>
      <CardWaitList>
        <div className="card__top">
          <div className="card__title">You have selected Edition</div>
          <div className="card__product-title">{product}</div>
        </div>
        <div className="card__content">
          <div className="card__preview">
            <img src="./images/gallery/product-table/founders.png"></img>
          </div> b 
          <div className="card-form">
            {/* <div className="card__form-amount">
                <InputAmount className="card__form-amount-input">
                  <IconInputMinus onClick={() => setAmount(formData.amount - 1)}/>
                  <input type="number" min="1" max="100" value={formData.amount} onChange={(e) => setAmount(e.target.value)}/>
                  <IconInputPlus onClick={() => setAmount(formData.amount + 1)}/>
                </InputAmount>
                <div className="card__form-cost-result">{totalPrice}$</div>
              </div> */}
              <InputForm
                placeholder="Full name"
                status={formStatus.name}
                value={formData.name}
                errorShow={formValid}
                onChange={(value: string) => setParamsForm('name', value)}
              />
              <InputForm
                placeholder="Email"
                status={formStatus.email}
                value={formData.email}
                errorShow={formValid}
                onChange={(value: string) => setParamsForm('email', value)}
              />
              <div className="card-indent"></div>
              {isLoading ? (
                <div className="card-form__loader">
                  <LoaderCentererd />
                </div>
                
              ) : (
                <BtnFormWrapper className='card-form__btn' onClick={submitForm}>
                  <div className={`btn ${!formSubmit ? 'not-valid' : ''}`}>Check out</div>
                </BtnFormWrapper>
              )}
              <div className="card-form__placeholder">
                One Wehead is enough for spatial communication. No special equipment is needed for guest users. They can just use a laptop or smartphone with conventional camera.
              </div>
          </div>
        </div>

      </CardWaitList>
    </>
  );
};
