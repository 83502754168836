import styled from "@emotion/styled";


export const InputFormWrapper = styled.label`
position: relative;
display: block;
margin-top:  24px;

  .input-error {
    background: red;
  }
  label {
    display: block;
    margin-top: 24px;
  }

  input {
    border: 2px solid #EFEAEA;
    border-radius: 18px;
    padding: 13px 24px;
    font-size: 20px;
    font-weight: 400;
    line-height: 100%;
    color: #EFEAEA;
    width: 100%;
    @media screen and (min-width: 768px) {
      padding: 13px 24px 12px;
    }
  }
  input.input-error {
    background: rgba(207, 123, 140, 0.2);
    border: 2px solid #CF7B8C;
  }
  input::placeholder {
    color: #EFEAEA;;
  }
  .input-status {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
`;
