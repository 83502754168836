import { Theme } from "../../../types";
import { IInputForm } from "./interfaces";

import React, { useState } from "react";


import { ReactComponent as IconInputOk } from "../../../assets/svg/input-ok.svg";
import { ReactComponent as IconInputError } from "../../../assets/svg/input-error.svg"

import {
  InputFormWrapper
} from "./styles";

export const InputForm: React.FC<IInputForm> = ({
  placeholder,
  value,
  status,
  onChange,
  errorShow
}) => {

  function changeInput(value: string) {
    onChange(value);
  }





  return (
    <InputFormWrapper>
      <input className={`input ${(!status && errorShow) ? 'input-error' : ''}`}  placeholder={placeholder} onChange={(value:any) => changeInput(value.target.value)} value={value}/>
      { status ? <IconInputOk className="input-status" /> : (
        errorShow ? <IconInputError className="input-status" /> : <></>
      )}
      <span></span>
    </InputFormWrapper>
  );
};
