import styled from "@emotion/styled";

import { Anchor } from "../Anchor";

export const ScrollDownWrapper = styled(Anchor)`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  transition-property: opacity;
  transition-duration: var(--transition-duration);

  .scroll_down_text {
    margin-right: 2px;
  }
`;
