import { Theme } from "../../../types";
import { AnchorProps } from "./interfaces";
import { StyledAnchor } from "./styles";

export const Anchor: React.FC<AnchorProps> = ({
  children,
  variant = Theme.LIGHT,
  underline = false,
  ...props
}) => (
  <StyledAnchor variant={variant} underline={false} {...props}>
    {children}
  </StyledAnchor>
);
