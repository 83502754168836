import React, { useEffect } from "react";

import {
  DemoTitleWrapper,
  DemoTitlContainer
} from "./styles";


import { IDemoTitle } from "./interfaces";

export const DemoTitle: React.FC<IDemoTitle> = ({
  children
}) => {

  useEffect(() => {
    const init = async () => {
    };

    init();
  }, []);


  return (
    <>
      <DemoTitleWrapper>
        <DemoTitlContainer>
          {children}
        </DemoTitlContainer>
      </DemoTitleWrapper>
    </>
  );
};
