import styled from "@emotion/styled";


export const DemoTitleWrapper = styled.div`
background: #EFEAEA;
padding: 43px 24px 36px;
display: flex;
width: 100%;
box-sizing: border-box;
@media screen and (min-width: 768px) {
  align-items: centr;
  justify-content: center;
  padding: 64px 24px 41px;
}


 .demo-title-mobile__title {
  color: #000;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 36px;
  font-weight: 900;
  @media screen and (min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
    width: 47%;
    min-width: 420px;
  }
}

.demo-title-mobile__subtitle {
  font-size: 16px;
  font-family: Raleway;
  color: #000;
  margin-top: 15px;
  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 11px;
  }
}
`

export const DemoTitlContainer = styled.div`
max-width: 1138px;
padding: 0 10px;
width: 100%;
@media screen and (min-width: 768px) {
  display: flex;
  align-items: center;
  gap: 68px;
}
`

