import styled from "@emotion/styled";

import { ReactComponent as ChervonDown } from "../../../assets/svg/chervonDown.svg";
import { SelectVariant } from "./interfaces";

export const SelectWrapper = styled.div`
  position: relative;
`;

export const SelectStyled = styled.select<{
  variant: SelectVariant;
  errorText?: string;
}>`
  --color: ${({ variant, errorText, theme }) =>
    errorText
      ? theme.colors.pink[900]
      : variant === SelectVariant.LIGHT
      ? theme.colors.white[700]
      : theme.colors.black[900]};

  width: 100%;
  border: 1px solid
    ${({ variant, theme }) =>
      variant === SelectVariant.LIGHT
        ? theme.colors.white[700]
        : theme.colors.black[900]};
  padding: 24px;
  color: ${({ variant, errorText, theme }) =>
    errorText
      ? theme.colors.pink[900]
      : variant === SelectVariant.LIGHT
      ? theme.colors.white[700]
      : theme.colors.black[900]};
  transition-property: opacity, border-color;
  transition-duration: var(--transition-duration);
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";

  :focus {
    border-color: ${({ theme }) => theme.colors.blue.steel_dark};
  }

  ::placeholder {
    color: ${({ variant, theme }) =>
      variant === SelectVariant.LIGHT
        ? theme.colors.white[700]
        : theme.colors.black[900]}80;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

export const ChervonDownIcon = styled(ChervonDown)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 24px;

  & path {
    fill: ${({ variant, errorText, theme }) =>
      errorText
        ? theme.colors.pink[900]
        : variant === SelectVariant.LIGHT
        ? theme.colors.white[700]
        : theme.colors.black[900]};
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.pink[900]};
  margin-top: 0.25rem;
  font-size: 0.93rem;
`;
