import styled from "@emotion/styled";

import { Loader } from "../../../../shared/Loader";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormStyled = styled.form`
  display: grid;
  row-gap: 16px;

  @media screen and (min-width: 768px) {
    row-gap: 24px;
  }
`;

export const LoaderCentererd = styled(Loader)`
  display: flex !important;
  justify-content: center;
`;
