import styled from "@emotion/styled";

export const PopUpWrapper = styled.div`
.pop-up {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #000;
  color: #EFEAEA;
  overflow: auto
}
.pop-up__content {
  height: 100%;
}
  
.pop-up__btn-close {
  position: absolute;
  top: 17px;
  right: 11px;
  width: 24px;
  height: 24px;
  z-index: 10;
}
`;
