import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMedia } from "react-use";

import guestMode from "../../../assets/images/guest-mode-desc.jpg";
import guestModeMobile from "../../../assets/images/guest-mode-mobile.jpg";
import hostMode from "../../../assets/images/host-mode.jpg";
import hostModeMobile from "../../../assets/images/host-mode-mobile.jpeg";
import head from "../../../assets/images/head.jpg";

import { ThemeContext } from "../../../contexts/ThemeContext";
import { redirect } from "../../../utils/redirect";
import { initColors } from "../../../utils/initColors";
import { sendEventToGA } from "../../../utils/sendEventGA";
import { Theme } from "../../../types";
import { ButtonSize } from "../../shared/Button";
import { BulletSize } from "../../shared/Bullet";
import { PopUp } from "../../shared/PopUp";
import { GradientText } from "../../shared/GradientText";
import { DemoContent } from "../../widgets/DemoContent";
import { BulletStatementList } from "../../widgets/BulletStatementList";
import Layout from "../../widgets/Layout/Layout";
import { Section, SectionVariant } from "../../widgets/Section";
import { BackgroundVideo } from "../../widgets/BackgroundVideo";
import { BulletStatement } from "../../widgets/BulletStatement";
import { OrderSection } from "./components/OrderSection";
import { DemoTitle } from "./components/DemoTitle"
import { PhotoGallery } from "./components/PhotoGallery";
import { ButtonForDevelopers, ScrollDownOverlay } from "./main.styles";
import { Accordion } from "../../shared/Accordion";
import { DescriptList } from "../../shared/DescriptList";
import { Paragraph, SectionUl } from "./components/OrderSectionOld/styles";
import { GalleryProductTable } from "./components/GalleryProductTable";
import { FormWaitList } from "./components/FormWaitList";
import { FormBuy } from "./components/FormBuy";
import { AboutSection } from "./components/AboutSection";

interface ILandingPage {
  theme: Theme;
}

// interface IProductOptions {
//   label: string;
//   price?: string;
//   photoAmount: number;
// }

// interface IProduct {
//   pro: IProductOptions;
//   home: IProductOptions;
//   founders: IProductOptions;
// }

const LandingPage: React.FC<ILandingPage> = ({ theme }) => {
  const { pathname, hash } = useLocation();
  const isTablet = useMedia("(max-width: 768px)");

  const [guestModeActive, setGuestModeActive] = useState(false);
  const [hostModeActive, setHostModeActive] = useState(false);

  const toggleGuestMode = useCallback(() => {
    if (guestModeActive) {
      sendEventToGA(
        "guest_experience_close_click",
        "buttons_click",
        '"Exit" button click (guest experience)'
      );
    } else {
      sendEventToGA(
        "guest_experience_open_click",
        "buttons_click",
        '"Try it now" button click (guest experience)'
      );
    }
    setGuestModeActive(!guestModeActive);
    setHostModeActive(false);
  }, [guestModeActive]);

  const toggleHostMode = useCallback(() => {
    if (hostModeActive) {
      sendEventToGA(
        "host_experience_close_click",
        "buttons_click",
        '"Exit" button click (host experience)'
      );
    } else {
      sendEventToGA(
        "host_experience_open_click",
        "buttons_click",
        '"Try it now" button click (host experience)'
      );
    }
    setGuestModeActive(false);
    setHostModeActive(!hostModeActive);
  }, [hostModeActive]);

  const handleForDevelopersButtonClick = useCallback(() => {
    sendEventToGA(
      "for_developers_click",
      "buttons_click",
      '"For Developers" button click'
    );
    redirect("/dev");
  }, []);

  const [productGalleryDialogType, setGalleryDialogType] = useState<string>('');
  const [productGalleryDialog, setShowGalleryDialog] = useState<boolean>(false);

  const [productWaitListType, setWaitListDialogType] = useState<string>('');
  const [productWaitListDialog, setShowWaitListDialog] = useState<boolean>(false);

  const [productBuyType, setBuyType] = useState<string>('');
  const [productBuyDialog, setShowBuyDialog] = useState<boolean>(false);

  const product: any = {
    pro: {
      label: 'Pro',
      photoAmount: 8
    },
    home: {
      label: 'Home',
      photoAmount: 13
    },
    founders: {
      label: 'Founders',
      price: '2299',
      photoAmount: 9
    }

  }

  function changePopUp(value: boolean) {
    if (value) {
      document.body.style.cssText = 'overflow: hidden'
    } else {
      document.body.style.cssText = ''
    }
  }

  function openDialogProduct(type: string, key: string) {
    if (type == 'gallery') {
      openGalleryProduct(key);
    } else if (type == 'waitlist') {
      openWaitList(key);
    } else if (type == 'buy') {
      openProductBuy(key);
    }

  }


  function closeGalleryProduct() {
    setShowGalleryDialog(false);
    setGalleryDialogType('');
    changePopUp(false);
  }

  function openGalleryProduct(key: string) {
    setShowGalleryDialog(true);
    setGalleryDialogType(key);
    changePopUp(true);
  }

  function closeWaitList() {
    setShowWaitListDialog(false);
    setWaitListDialogType('');
    changePopUp(false);
  }
  function openWaitList(key: string) {
    setShowWaitListDialog(true);
    setWaitListDialogType(key);
    changePopUp(true);
  }
  function closeProductBuy() {
    setShowBuyDialog(false);
    setBuyType('');
    changePopUp(false);
  }
  function openProductBuy(key: string) {
    setShowBuyDialog(true);
    setBuyType(key);
    changePopUp(true);
  }


  useEffect(() => {
    initColors(theme);
  }, [theme]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= window.innerHeight / 4) {
        const scrollDownElement = document.getElementById("scroll_down");

        if (scrollDownElement) {
          scrollDownElement.style.opacity = "0";
          scrollDownElement.style.pointerEvents = "none";
        }

        window.removeEventListener("scroll", handleScroll);
      }
    };

    const handleHangup = (e: MessageEvent) =>
      e.data === "hangup" && toggleGuestMode();

    document.title = "Wehead";

    // Обработчик скролла для скрытия элемента ScrollDownOverlay
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    // Обработчик сообщения из iframe guest-мода для выключепния интерактивного экрана guest-мода
    window.addEventListener("message", handleHangup);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("message", handleHangup);
    };
  }, [toggleGuestMode]);

  useEffect(() => {
    if (hash) {
      redirect(`${pathname}${hash}`);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return (
    <ThemeContext.Provider value={{ theme }}>
      <Layout>
        <div
          style={{
            background: "url('/images/main-video-preview.jpg')",
            width: "100%",
            height: "99vh",
          }}
        >
          <video
            id="vvv"
            autoPlay playsInline muted loop
            onCanPlayThrough={() => {
              const vvv = document.getElementById('vvv');
              if (vvv != null) {
                vvv.style.opacity = '1'
              }
            }}
            style={{
              width: "100%",
              height: "100vh",
              objectFit: "cover",
              // position: "fixed", 
              left: 0,
              top: 0,
              zIndex: -100,
              opacity: 0,
            }}
          >
            <source src={isTablet ? "/videos/main-mobile.mp4" : "/videos/main.mp4"} type='video/mp4'></source>
          </video>
        </div>
        {
          !isTablet ?
            <AboutSection />
            :
            <Section
              title="THIS IS WEHEAD"
              description={
                <DescriptList
                  text="First spatial video device featuring a unique face-shaped display and dynamic spatial movements, tailored for:"
                  list={[
                    "Immersive Virtual meetings (Call Mode)",
                    "Pre-loaded “talking head” (Video Player Mode)",
                    "Digital Human interactions (Virtual Agent Mode, beta)"
                  ]}
                ></DescriptList>
              }
              id="this_is_wehead"
              minHeight="50vh"
            >
              {
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginTop: 16,
                  }}
                >
                  <Accordion trigger="Full-size face">
                    <Paragraph>
                      face features are precisely embodied by HD displays forming a head shape
                    </Paragraph>
                  </Accordion>
                  <Accordion trigger="Head gestures">
                    <Paragraph>
                      guest head movements are mimicked by the Wehead's motors in real time to allow guest to focus on every aspect of communication
                    </Paragraph>
                  </Accordion>
                  <Accordion trigger="Eye contact">
                    <Paragraph>
                      feels incredibly real due to the fact that camera is positioned right at the “eye's display”
                    </Paragraph>
                  </Accordion>
                  <Accordion trigger="Spatial audio">
                    <Paragraph>
                      mic and speaker move together with the head which allows both guest and host to sense each other’s presence in the rooms
                    </Paragraph>
                  </Accordion>
                </div>
              }
            </Section>
        }



        <OrderSection openDialog={openDialogProduct} />

        {/* {isTablet ? : <OrderSection />} */}

        
        <PhotoGallery />

        <DemoTitle>
          <>
            <div className="demo-title-mobile__title">Immersive Virtual <br />Meeting - Host</div>
            <div className="demo-title-mobile__subtitle">Try Wehead as a Host in our Call Mode. <br />Drag it to change the view angle</div>
          </>
        </DemoTitle>
        <Section
          minHeight="90vh"
          backgroundContent={
            <DemoContent
              badge="Call mode"
              backgroundTextProps="HOST"
              title="host_mode"
              src="https://wehead-demo.web.app/host"
              imageStubUrl={isTablet ? hostModeMobile : hostMode}
              active={hostModeActive}
              toggleActive={toggleHostMode}
              buttonText="Start host demo"
            >
            </DemoContent>
          }
        />
        <DemoTitle>
          <>
            <div className="demo-title-mobile__title">Immersive Virtual  <br />Meeting - Guest</div>
            <div className="demo-title-mobile__subtitle">Try Wehead as a Guest in our Call Mode. <br />Move your head to see other people in the <br />room</div>
          </>
        </DemoTitle>

        <Section
          minHeight="90vh"
          backgroundContent={
            <DemoContent
              title="guest_mode"
              badge="Call mode"
              backgroundTextProps="GUEST"
              src="https://wehead-demo.web.app/guest"
              imageStubUrl={isTablet ? guestModeMobile : guestMode}
              active={guestModeActive}
              toggleActive={toggleGuestMode}
              buttonText="Start guest demo"
            >
            </DemoContent>
          }
        />
      </Layout >
      <PopUp show={productGalleryDialog} closePopUp={closeGalleryProduct}>
        <GalleryProductTable
          amount={product[productGalleryDialogType]?.photoAmount}
          product={product[productGalleryDialogType]?.label}
          type={productGalleryDialogType} />
      </PopUp>
      <PopUp show={productWaitListDialog} closePopUp={closeWaitList}>
        <FormWaitList
          product={product[productWaitListType]?.label}
          closeForm={closeWaitList}
        />
      </PopUp>
      <PopUp show={productBuyDialog} closePopUp={closeProductBuy}>
        <FormBuy
          product={product[productBuyType]?.label}
          price={product[productBuyType]?.price}
        />
      </PopUp>
    </ThemeContext.Provider >
  );
};

export default LandingPage;
