import React, {  useEffect,  useState } from "react";

import { IOrderSection } from './interfaces'
import { useMedia } from "react-use";

import { Bullet, BulletVariant, BulletSize } from "../../../../shared";
import {
  TabMenuProduct,
  TabProduct,
  PreviewProduct,
  BtnPreview,
  CardContentProduct,
  ProductContent,
  ProductCardActions,
  ProductCard,
  OrderSectionTableTop,
  OrderSectionTableContainer,
  OrderSectionTableTitle,
  OrderSectionTableWrapper,
  ProductList
} from "./styles";


export const OrderSection: React.FC<IOrderSection> = ({
  openDialog
}) => {
  const isTablet = useMedia("(max-width: 768px)");



  const [showProductPro, setShowProductPro] = useState(false);
  const [showProductHome, setShowProductHome] = useState(false);
  const [showProductFounders, setShowProductFounders] = useState(false);

  function scheduleDemo() {
    const a = document.createElement("a");
    a.href = `https://calendly.com/go-wehead/30/`;
    a.target = isTablet ? "_blank" : "_blank";
    a.click();
    a.remove();
  }




  useEffect(() => {
    const init = async () => {
    };

    init();
  }, []);

  return (
    <>
    <OrderSectionTableWrapper>
      <OrderSectionTableContainer>
        <OrderSectionTableTitle>
          <span className="section__title">
            Our Editions
          </span>
        </OrderSectionTableTitle>
        <OrderSectionTableTop>
          <TabMenuProduct>
            <TabProduct href="#product-pro">
              <Bullet hoverStyle={true} variant={BulletVariant.STROKE} size={BulletSize.MINI} sizeDesctop={BulletSize.BIG}>
                Pro
              </Bullet>
            </TabProduct>
            <TabProduct href="#product-home">
              <Bullet hoverStyle={true} variant={BulletVariant.STROKE} size={BulletSize.MINI} sizeDesctop={BulletSize.BIG}>
                Home
              </Bullet>
            </TabProduct>
            <TabProduct href="#product-founders">
              <Bullet hoverStyle={true} variant={BulletVariant.STROKE} size={BulletSize.MINI} sizeDesctop={BulletSize.BIG}>
                Founders
              </Bullet>
            </TabProduct>
          </TabMenuProduct>
        </OrderSectionTableTop>
        <ProductList>
          <ProductCard>
            <a id="product-pro" style={{ position: 'absolute', top: isTablet ? '-100px' : '-120px' }}></a>
            <PreviewProduct>
              <img src="./images/gallery/product-table/pro.webp"></img>
              <BtnPreview onClick={() => openDialog('gallery', 'pro')}>Gallery</BtnPreview>
            </PreviewProduct>
            <CardContentProduct>
              <div className="top">
                <div className="name">
                  Pro
                </div>
                <div className="badge">
                  {
                    isTablet ?
                      <Bullet variant={BulletVariant.STROKE} size={BulletSize.MINI}>
                        Subscription
                      </Bullet> :
                      <></>
                  }
                </div>
              </div>
              <div className="price__container">
                <div className="price">from $199/month</div>
                {
                  !isTablet ?
                    <Bullet variant={BulletVariant.STROKE} size={BulletSize.MINI}>
                      Subscription
                    </Bullet> :
                    <></>
                }

              </div>
              <a id="morePro" style={{ position: 'absolute', top: '-160px' }} />
              <ProductContent>
                <div>
                  <b>Wehead Pro revolutionizes business interactions by introducing an emotional element, understanding user ambiguity, and exploring various outcomes for more personalized customer relationships.</b>
                  <br /><br />
                  <span>
                    This edition shines in hospitality, sales, and customer service sectors where maintaining high-quality interactions is crucial.
                  </span>
                  <br />
                  <ul>
                    <li>Virtual Agent for Sales and Hospitality</li>
                    <li>Emotional Understanding</li>
                    <li>Solution Exploration</li>
                  </ul>
                </div>
                {showProductPro && (<div>
                  <b>Virtual Agent for Sales and Hospitality</b><br />
                  Improve customer experience with a virtual assistant that can manage inquiries, reservations, and complaints.
                  <br />
                  <br />
                  <b>
                    Emotional Understanding
                  </b><br />
                  The AI is capable of recognizing and responding appropriately to the emotional tone of the conversation, ensuring a more empathetic customer interaction.
                  <br /><br />
                  <b>
                    Human-like responses (Beta mode)
                  </b><br />
                  Advanced algorithms generate responses that mirror human conversation, enhancing the authenticity of your interaction.
                  <br /><br />
                  <b>
                    Solution Exploration
                  </b><br />
                  If a customer is unsure of what they need, Wehead Pro can guide them towards a suitable outcome by presenting a range of options and suggestions.
                  <br /><br />
                  <div className="divider"></div>
                  <br />
                  <b>
                    Additional Specs
                  </b><br />
                  Wehead Pro is a robust platform that seamlessly integrates with your existing customer relationship management systems, designed to handle high-volume interactions. The AI's understanding and response capabilities are continuously updated to provide the best possible service.
                  <br /><br />
                  <b>
                    Coming Soon
                  </b><br />
                  Anticipate more in-depth insights and analytics features to better understand your customers and their needs, resulting in an enhanced customer service experience.
                  <br /><br />
                </div>)}
              </ProductContent>
              <ProductCardActions>
                <a href="#morePro" className="btn btn--outliner" onClick={(e) => {
                  if (!showProductPro) {
                    e.preventDefault()
                    setShowProductPro(!showProductPro)
                  } else {
                    setTimeout(() => { setShowProductPro(!showProductPro) }, 300)
                  }
                }}>
                  {showProductPro ? 'View Less' : 'View More'}
                </a>

                <div className="btn" onClick={scheduleDemo} >
                  Schedule Demo
                </div>
              </ProductCardActions>
            </CardContentProduct>
          </ProductCard>
          <ProductCard>
            <a id="product-home" style={{ position: 'absolute', top: isTablet ? '-100px' : '-120px' }}></a>
            <PreviewProduct>
              <img src="./images/gallery/product-table/home.webp"></img>
              <BtnPreview onClick={() => openDialog('gallery', 'home')}>Gallery</BtnPreview>
            </PreviewProduct>
            <CardContentProduct>
              <div className="top">
                <div className="name">
                  Home
                </div>
                <div className="badge">
                  {
                    isTablet ?
                      <Bullet variant={BulletVariant.STROKE} size={BulletSize.MINI}>
                        Coming Soon
                      </Bullet> :
                      <></>
                  }
                </div>
              </div>
              <div className="price__container">
                <div className="price">$4950</div>
                {
                  !isTablet ?
                    <Bullet variant={BulletVariant.STROKE} size={BulletSize.MINI}>
                      Coming Soon
                    </Bullet> :
                    <></>
                }
              </div>
              <ProductContent>
                <a id="moreHome" style={{ position: 'absolute', top: '-160px' }} />
                <div>
                  <b>The Wehead Home is the perfect smart companion for your home, designed to offer a new level of interactive and entertaining experiences.</b>
                  <br /><br />
                  <span>
                    Ideal for families seeking interactive learning, for friends enjoying trivia games or music lovers wanting to explore new genres.
                  </span>
                  <br />
                  <ul>
                    <li>Visual ChatGPT with personality selection</li>
                    <li>Context Understanding (Beta mode)</li>
                    <li>Human-like responses (Beta mode)</li>
                    <li>Podcasts and Kids Entertainment</li>
                  </ul>
                </div>
                {showProductHome && (<div>
                  <b>Visual ChatGPT with personality selection</b><br />
                  Choose your AI's personality to suit your mood and conversation style, making interactions fun and personalized.
                  <br />
                  <br />
                  <b>
                    Context Understanding (Beta mode)
                  </b><br />
                  The AI is equipped to understand tone of voice, intonation, non-verbal cues, and even micro-expressions, making conversations natural and engaging.
                  <br /><br />
                  <b>
                    Human-like responses (Beta mode)
                  </b><br />
                  Advanced algorithms generate responses that mirror human conversation, enhancing the authenticity of your interaction.
                  <br /><br />
                  <b>
                    Podcasts and Kids Entertainment
                  </b><br />
                  Enjoy a vast library of podcasts or keep your children engaged with a range of kids-friendly content.
                  <br /><br />
                  <div className="divider"></div>
                  <br />
                  <b>
                    Additional Specs
                  </b><br />
                  Wehead Home is a compact device that fits into any room decor, equipped with an advanced voice recognition system, high-quality speakers, and a vibrant screen for visual interactions. Our AI is updated continuously for performance improvements and new features.
                  <br /><br />
                  <b>
                    Coming Soon
                  </b><br />
                  We are working on expanding the range of personalities for ChatGPT and incorporating more languages to cater to our global user base.
                  <br /><br />
                </div>)}
              </ProductContent>
              <ProductCardActions>
                <a href="#moreHome" className="btn btn--outliner" onClick={(e) => {
                  if (!showProductHome) {
                    e.preventDefault()
                    setShowProductHome(!showProductHome)
                  } else {
                    setTimeout(() => { setShowProductHome(!showProductHome) }, 300)
                  }
                }}>
                  {showProductHome ? 'View Less' : 'View More'}
                </a>

                <div className="btn" onClick={() => openDialog('waitlist', 'home')}>
                  Join Waitlist
                </div>
                <div className="btn" onClick={scheduleDemo}>
                  Schedule Demo
                </div>
              </ProductCardActions>
            </CardContentProduct>
          </ProductCard>
          <ProductCard>
            <a id="product-founders" style={{ position: 'absolute', top: isTablet ? '-100px' : '-120px' }}></a>
            <PreviewProduct>
              <img src="./images/gallery/product-table/founders.webp"></img>
              <BtnPreview onClick={() => openDialog('gallery', 'founders')}>Gallery</BtnPreview>
            </PreviewProduct>
            <CardContentProduct>
              <div className="top">
                <div className="name">
                  Founders
                </div>
                <div className="badge">
                  {
                    isTablet ?
                      <Bullet variant={BulletVariant.STROKE} size={BulletSize.MINI}>
                        Available Now
                      </Bullet> :
                      <></>
                  }
                </div>
              </div>
              <div className="price__container">
                <div className="price">$2299</div>
                {
                  !isTablet ?
                    <Bullet variant={BulletVariant.STROKE} size={BulletSize.MINI}>
                      Available Now
                    </Bullet> :
                    <></>
                }
              </div>


              <ProductContent>
                <a id="moreFounders" style={{ position: 'absolute', top: '-160px' }} />
                <div>
                  <b>The Wehead Founders Edition is for the explorers and innovators - the developers and exhibitors looking to showcase the cutting-edge capabilities of AI technology.</b>
                  <br /><br />
                  <span>
                    Developers can delve into the intricate workings of our AI, while exhibitors can dazzle audiences with this highly advanced and interactive tech.
                  </span>
                  <br />
                  <ul>
                    <li>Deep Dive for Developers</li>
                    <li>Exhibitor Showcase</li>
                    <li>AI Exploration</li>
                  </ul>
                </div>
                {showProductFounders && (<div>
                  <b>Deep Dive for Developers</b><br />
                  Get hands-on with the technology and explore the potential of AI with full access to our APIs and SDKs.
                  <br />
                  <br />
                  <b>
                    Exhibitor Showcase
                  </b><br />
                  Create an unforgettable impression at trade shows, exhibitions, or even in your showroom with this interactive, attention-grabbing tech.
                  <br /><br />
                  <b>
                    AI Exploration
                  </b><br />
                  Experience the latest in AI technology, with constant updates and improvements to keep you on the forefront of innovation.
                  <br /><br />
                  <div className="divider"></div>
                  <br />
                  <b>
                    Additional Specs
                  </b><br />
                  Wehead Founders Edition offers a versatile platform compatible with various programming languages and environments. The system is robust, capable of handling intensive tasks, and offers comprehensive documentation and support for developers.
                  <br /><br />
                  <b>
                    Coming Soon
                  </b><br />
                  Stay tuned for the release of our advanced AI training modules, aimed to provide an even deeper understanding and customization capabilities to developers
                  <br /><br />
                </div>)}
              </ProductContent>
              <ProductCardActions>
                <a href="#moreFounders" className="btn btn--outliner" onClick={(e) => {
                  if (!showProductFounders) {
                    e.preventDefault()
                    setShowProductFounders(!showProductFounders)
                  } else {
                    setTimeout(() => { setShowProductFounders(!showProductFounders) }, 300)
                  }
                }}>
                  {showProductFounders ? 'View Less' : 'View More'}
                </a>
                <div className="btn" onClick={() => openDialog('buy', 'founders')}>
                  Buy Now
                </div>
                <div className="btn" onClick={scheduleDemo}>
                  Schedule Demo
                </div>
              </ProductCardActions>
            </CardContentProduct>
          </ProductCard>
        </ProductList>
      </OrderSectionTableContainer>
      </OrderSectionTableWrapper>
    </>
  );
};
