export enum BulletVariant {
  STROKE = "stroke",
  FILLED = "filled",
  TEXT = "text",
}

export enum BulletSize {
  DEFAULT = "default",
  BIG = "big",
  MINI = "mini"
}

export interface IBullet {
  variant?: BulletVariant[keyof BulletVariant];
  size?: BulletSize;
  sizeDesctop?: BulletSize;
  className?: string;
  children: React.ReactNode;
  hoverStyle?: boolean
}
