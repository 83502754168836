export const sendEventToGA = (
  eventName: string,
  eventCategory: string,
  eventLabel?: string
) => {
  // @ts-ignore
  gtag("event", eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
  });
};
