import { ChangeEvent, HTMLInputTypeAttribute } from "react";

export enum SelectVariant {
  LIGHT = "light",
  DARK = "dark",
}

export interface ISelect {
  name?: string;
  id?: string;
  value?: string;
  onChange?(e: ChangeEvent): void;
  placeholder?: string;
  errorText?: string;
  type?: HTMLInputTypeAttribute;
  variant?: SelectVariant;
  required?: boolean;
  children: JSX.Element | JSX.Element[];
}
