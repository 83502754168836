import { ChangeEvent, HTMLInputTypeAttribute } from "react";

export enum InputVariant {
  LIGHT = "light",
  DARK = "dark",
}

export interface IInput {
  name?: string;
  id?: string;
  value?: string;
  onChange?(e: ChangeEvent): void;
  placeholder?: string;
  errorText?: string;
  type?: HTMLInputTypeAttribute;
  variant?: InputVariant;
  required?: boolean;
}
