import React from "react";

import { Theme } from "../types";

interface IThemeContext {
  theme: Theme;
}

export const ThemeContext = React.createContext<IThemeContext>({
  theme: Theme.DARK,
});
