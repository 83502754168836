import { Theme } from "../../../types";

export enum ButtonSize {
  SMALL = "small",
  BIG = "big",
}

export enum ButtonVariant {
  OUTLINED = "OUTLINED",
  TEXT = "TEXT",
  FILL = "FILL",
}

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  themeButton?: Theme;
}
