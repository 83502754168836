import { useMemo } from "react";

import { StatusMessageProps, StatusMessageVariants } from "./interfaces";
import { GradientText } from "../../../../shared/GradientText";
import {
  ButtonsWrapper,
  PaymentStatusDescription,
  PaymentStatusTitleWrapper,
} from "./styles";
import { Button } from "../../../../shared/Button";
import { redirect } from "../../../../../utils/redirect";

export const StatusMessage: React.FC<StatusMessageProps> = ({
  title,
  description,
  status = StatusMessageVariants.FAILURE,
  buttonPreset,
  className,
}) => {
  const button = useMemo(() => {
    if (buttonPreset) {
      return buttonPreset;
    }

    let handleClick: () => void;
    let message: string;

    switch (status) {
      case StatusMessageVariants.PROCESSING:
        message = "Reload page";
        handleClick = () => window.location.reload();
        break;
      case StatusMessageVariants.FAILURE:
        message = "Refill payment details";
        handleClick = () => redirect("/#form");
        break;
      default:
        message = "Go back";
        handleClick = () => redirect("/");
        break;
    }

    return { message, handleClick };
  }, [status, buttonPreset]);

  return (
    <div className={className}>
      <PaymentStatusTitleWrapper status={status}>
        {status === StatusMessageVariants.SUCCESS ? (
          <GradientText>{title}</GradientText>
        ) : (
          <span>{title}</span>
        )}
      </PaymentStatusTitleWrapper>
      <PaymentStatusDescription>{description}</PaymentStatusDescription>
      <ButtonsWrapper>
        <Button onClick={button.handleClick}>{button.message}</Button>
      </ButtonsWrapper>
    </div>
  );
};
