import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const InputRadio = styled.input`
  display: none;

  :checked ~ div {
    opacity: 1;

    ${({ theme }) =>
      css`
        --border-width: 4px;
        background: ${theme.colors.gradient.linkman};
        background-clip: text;
        text-fill-color: white;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          border-radius: var(--border-radius);
          padding: var(--border-width);
          background: ${theme.colors.gradient.linkman};
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      `};
  }
`;

export const InputRadioContent = styled.div`
  position: relative;
  padding: 24px 20px;
  cursor: pointer;
  transition-duration: var(--transition-duration);

  --border-width: 2px;
  --border-radius: 24px;
  ${({ theme }) =>
    css`
      background: ${theme.colors.white[700]};
      background-clip: text;
      text-fill-color: white;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        border-radius: var(--border-radius);
        padding: var(--border-width);
        background: ${theme.colors.white[700]};
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    `};
`;
