import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Theme } from "../../../types";
import { ButtonSize, ButtonVariant, IButton } from "./interfaces";

export const ButtonWrapper = styled.button<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ variant, theme }) =>
    variant === ButtonVariant.OUTLINED
      ? `2px solid ${theme.colors.blue.steel}`
      : "none"};
  border-radius: ${(props) =>
    props.size === ButtonSize.SMALL ? "12px" : "18px"};
  font-weight: 700;
  opacity: ${({ disabled }) => (disabled ? 0.34 : 1)};
  transition-property: opacity, border-color;
  transition-duration: var(--transition-duration);
  cursor: pointer;
  ${(props) =>
    props.variant === ButtonVariant.FILL
     ? css`
     border-radius: 24px;
     background: #0EAECA;
     ` : ``
  }
  

  ${(props) =>
    props.size === ButtonSize.SMALL
      ? css`
          border-radius: 12px;
          padding: 12px 24px;
          font-size: 16px;
        `
      : css`
          border-radius: 18px;
          padding: 24px 84px;
          font-size: 24px;
        `}

  ${({ variant, themeButton, theme }) =>
    themeButton === Theme.DARK
      ? css`
          color: ${theme.colors.white[700]};
          background-color: ${variant === ButtonVariant.OUTLINED
            ? `${theme.colors.black[900]}57`
            : "none"};
        `
      : css`
          color: ${theme.colors.black[900]};
          background-color: ${variant === ButtonVariant.OUTLINED
            ? `${theme.colors.white[700]}57`
            : "none"};
        `}

  :hover {
    color: ${({ theme }) => theme.colors.white[700]} !important;
    background-color: ${(props) => props.theme.colors.blue.steel};
  }

  :active {
    color: ${({ theme }) => theme.colors.white[700]} !important;
    background-color: ${(props) => props.theme.colors.blue.steel_dark};
  }

  :focus {
    outline: 1px solid
      ${({ themeButton, theme }) =>
        themeButton === Theme.DARK
          ? theme.colors.white[700]
          : theme.colors.black[900]};
  }
`;
